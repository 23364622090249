<template>
  <div v-if="meta && meta.total > 0" class="row">
    <div class="col">
      <div class="d-flex justify-content-center">
        <a-pagination
          v-model:current="page"
          :page-size="meta.per_page"
          :show-size-changer="false"
          :show-total="showTotal"
          :total="meta.total"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import { PaginationMeta } from '@/api/PaginatedResponse'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

const APagination = asyncComponentWrapper(() => import('ant-design-vue/es/pagination'))

const props = defineProps({
  modelValue: {
    type: Number as PropType<number | undefined>,
    default: 1,
  },
  meta: {
    type: Object as PropType<PaginationMeta | undefined | null>,
    default: null,
  },
})

const emits = defineEmits<{
  (e: 'update:modelValue', value: number): void
}>()

const page = computed({
  get: () => props.modelValue,
  set(value: number) {
    emits('update:modelValue', value)
  },
})

const showTotal = (total: number, range: number[]) => {
  return `${range[0]}-${range[1]} of ${total} items`
}
</script>
