export const scrollToElement = (element: HTMLElement) => {
    element.scrollIntoView({
        behavior: 'smooth',
    })
}

export const setDocumentTitle = (title: string | undefined) => {
    document.title = title
      ? `${title} - IntelliKid Systems`
      : 'IntelliKid Systems'
}

