import { useBusiness } from '@/store/Business'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const useCurrentBusiness = () => {
  const businessState = useBusiness()
  const route = useRoute()

  const business = computed(
    () =>
      businessState.getters.businessData.find((b) => route.params.businessId === b.slug) ??
      businessState.getters.mainBusiness,
  )

  const businessId = computed(() => String(business.value?.id))
  const businessSlug = computed(() => business.value?.slug ?? businessState.getters.businessSlug)

  return {
    business,
    businessId,
    businessSlug,
  }
}

export default useCurrentBusiness // hint: typescript auto imports work better with name exports
