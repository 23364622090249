import { Actions, Context, createComposable, Getters, Module, Mutations } from 'vuex-smart-module'
import PermissionDto, { transformBusinessUserAbilityResponseToDto } from '@/dto/Auth/PermissionDto'
import BusinessDto, {
  BusinessListResponse,
  transformBusinessResponseToDto,
} from '@/dto/Business/BusinessDto'
import { BusinessUserProfileResponse } from '@/dto/Auth/ProfileDto'
import RoleDto, { transformBusinessUserRoleResponseToDto } from '@/dto/Auth/RoleDto'
import { auth, AuthEvents } from '@/store/Auth'
import { dashboard } from '@/store/Dashboard'
import { getBusinesses, getBusinessesUsers, getBusinessInfo } from '@/api/Business'
import { Store } from 'vuex'
import BusinessRepository, { PersistedBusinessState } from '@/store/Business/business.repository'
import { GeneralSettingsItem } from '@/dto/common/GeneralSettingsDto'
import { GeneralSettingsKey } from '@/shared'
import { FileUploadItemResponseDTO } from '@/dto/common/files'
import { getBusinessConfig } from '@/api/Business/config'
import { BusinessConfigResponseDTO } from '@/dto/Business/Config/Response'
import { LocationResponseDto } from '@/dto/Business/Location/Response'
import { getBusinessData } from '@/api/Business/businessData'
import { LocationFeatureDto } from '@/dto/Business/Location/Response/LocationProductResponseDto'
import { CommonCountryResponseDto } from '@/dto/common/Country/Response'
import { RuntimeModule } from '@/store/moduleType.js'
import { getBusinessSlugFromUrl } from '@/helpers/url.helper'
import { notifyError } from '@/helpers/notification.helper'

export const enum BusinessEvents {
  Initialized = 'initialized',
}

const localBusinessStorage = BusinessRepository.getLocalInstance()
const sessionBusinessStorage = BusinessRepository.getSessionInstance()

class BusinessState {
  business: PersistedBusinessState = new PersistedBusinessState()
  businessData: BusinessDto[] = []
  profile: BusinessUserProfileResponse | null = null
  settings: GeneralSettingsItem[] = []
  config: BusinessConfigResponseDTO[] = []
  roles: RoleDto[] = []
  abilities: PermissionDto[] = []
  businesses: BusinessListResponse[] = []
  locations: LocationResponseDto[] = []
  isStoreLoaded = false
  features: LocationFeatureDto[] = []
  country: CommonCountryResponseDto | null = null
}

class ResettableState {
  businessState: BusinessState = new BusinessState()
}

class BusinessGetters extends Getters<ResettableState> {
  get profile() {
    return this.state.businessState.profile
  }

  get timezoneSetting() {
    return this.state.businessState.settings.find((s) => GeneralSettingsKey.Timezone === s.key)
  }

  get config() {
    return this.state.businessState.config
  }

  get abilities() {
    const abilities: PermissionDto[] = []
    const mergedAbilities = abilities.concat(this.state.businessState.abilities)

    // role abilities
    for (const role of this.state.businessState.roles) {
      mergedAbilities.push(...role.abilities)
    }

    return mergedAbilities
  }

  get businessData(): BusinessDto[] {
    return this.state.businessState.businessData
  }

  get businesses(): BusinessListResponse[] {
    return this.state.businessState.businesses
  }

  get businessSlug(): string | null {
    return getBusinessSlugFromUrl() ?? this.state.businessState.business.slug
  }

  get mainBusinessId(): string | null {
    return this.getters.businessData.find((b) => b.slug === this.getters.businessSlug)?.id ?? null
  }

  get mainBusiness(): BusinessDto | null {
    if (null === this.getters.mainBusinessId) {
      return null
    }

    return (
      this.getters.businessData.find(
        (business: BusinessDto) => this.getters.mainBusinessId === business.id,
      ) || null
    )
  }

  get locations(): LocationResponseDto[] {
    return this.state.businessState.locations
  }

  get isStoreLoaded(): boolean {
    return this.state.businessState.isStoreLoaded
  }

  get features(): LocationFeatureDto[] {
    return this.state.businessState.features
  }

  get country(): CommonCountryResponseDto | null {
    return this.state.businessState.country
  }

  get businessUserRoles(): RoleDto[] {
    return this.state.businessState.roles
  }
}

class BusinessMutations extends Mutations<ResettableState> {
  setProfile(profileDto: BusinessUserProfileResponse | null) {
    this.state.businessState.profile = profileDto
  }

  setAbilities(abilities: PermissionDto[]) {
    this.state.businessState.abilities = abilities
  }

  setRoles(roles: RoleDto[]) {
    this.state.businessState.roles = roles
  }

  setBusinesses(businesses: BusinessListResponse[]) {
    this.state.businessState.businesses = businesses
  }

  setSessionBusiness(businessSlug: string | null) {
    this.state.businessState.business.slug = businessSlug
    sessionBusinessStorage.state = this.state.businessState.business
  }

  setMainBusiness(businessSlug: string | null) {
    this.state.businessState.business.slug = businessSlug
    localBusinessStorage.state = this.state.businessState.business
  }

  setBusinessSlug(businessSlug: string | null) {
    this.state.businessState.business.slug = businessSlug
  }

  setLocations(locations: LocationResponseDto[]) {
    this.state.businessState.locations = locations
  }

  setSettings(settings: GeneralSettingsItem[]) {
    this.state.businessState.settings = settings
  }

  setConfig(config: BusinessConfigResponseDTO[]) {
    this.state.businessState.config = config
  }

  setFeatures(features: LocationFeatureDto[]) {
    this.state.businessState.features = features
  }

  setIsStoreLoaded(state: boolean) {
    this.state.businessState.isStoreLoaded = state
  }

  setBusinessData(business: BusinessDto) {
    const index = this.state.businessState.businessData.findIndex((b) => b.id === business.id)

    // find and replace or append businesses data
    if (index !== -1) {
      this.state.businessState.businessData[index] = business
    } else {
      this.state.businessState.businessData.push(business)
    }
  }

  setBusinessLogo({
    businessId,
    logo,
  }: {
    businessId: string
    logo: FileUploadItemResponseDTO | null
  }) {
    const index = this.state.businessState.businessData.findIndex(
      (business) => business.id === businessId,
    )
    if (index !== -1) {
      this.state.businessState.businessData[index].logo = logo
    }
  }

  setUserPhoto(photo: FileUploadItemResponseDTO | null) {
    if (this.state.businessState.profile !== null) {
      this.state.businessState.profile.photo = photo
    }
  }

  reset() {
    this.state.businessState = new BusinessState()
  }

  setCountry(country: CommonCountryResponseDto | null) {
    this.state.businessState.country = country
  }

  setLocation(location: LocationResponseDto) {
    const locIndex = this.state.businessState.locations.findIndex((loc) => loc.id === location.id)

    this.state.businessState.locations[locIndex] = location
  }
}

class BusinessActions extends Actions<
  ResettableState,
  BusinessGetters,
  BusinessMutations,
  BusinessActions
> {
  auth?: Context<typeof auth>
  dashboard?: Context<typeof dashboard>

  $init(store: Store<unknown>): void {
    this.auth = auth.context(store)
    this.dashboard = dashboard.context(store)
    store.subscribeAction(({ type }) => {
      if (`AuthModule/${AuthEvents.OnUserChange}` === type) {
        this.dispatch('onUserChange')
      }
      if (`AuthModule/${AuthEvents.OnLogout}` === type) {
        this.dispatch('_reset')
      }
    })
    this.dispatch('_init')
  }

  async _init() {
    this.commit('setIsStoreLoaded', false)

    if (
      localBusinessStorage.state !== null &&
      localBusinessStorage.isStateValid(localBusinessStorage.state)
    ) {
      await this.dispatch(
        'setMainBusiness',
        !!localBusinessStorage.state?.slug ? localBusinessStorage.state.slug : null,
      )
    }

    if (
      sessionBusinessStorage.state !== null &&
      sessionBusinessStorage.isStateValid(sessionBusinessStorage.state)
    ) {
      await this.dispatch(
        'setSessionBusiness',
        !!sessionBusinessStorage.state?.slug ? sessionBusinessStorage.state.slug : null,
      )
    }

    this.commit('setIsStoreLoaded', true)
  }

  [BusinessEvents.Initialized]() {
    return
  }

  setIsStoreLoaded(state: boolean) {
    this.commit('setIsStoreLoaded', state)
  }

  async onUserChange() {
    this.commit('setIsStoreLoaded', false)

    await this.dispatch('getBusinesses')
    await this.dispatch('loadBusinessStore')

    this.commit('setIsStoreLoaded', true)

    await this.dispatch(BusinessEvents.Initialized)
  }

  _reset() {
    localBusinessStorage.state = null
    sessionBusinessStorage.state = null
    this.mutations.reset()
  }

  async getBusinessUser() {
    if (
      null === this.getters.mainBusinessId ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      null === this.auth.getters.user
    ) {
      return
    }

    try {
      const {
        data: { data: businessUserResponse },
      } = await getBusinessesUsers(
        this.getters.mainBusinessId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.auth.getters.user.id,
        true,
      )

      const profile = businessUserResponse.profile
      const roles = businessUserResponse.roles.map((role) =>
        transformBusinessUserRoleResponseToDto(role),
      )
      const abilities = businessUserResponse.abilities.map((ability) =>
        transformBusinessUserAbilityResponseToDto(ability),
      )
      this.mutations.setProfile(profile)
      this.mutations.setAbilities(abilities)
      this.mutations.setRoles(roles)
    } catch (e: unknown) {
      notifyError('Something went wrong. Could not get Business User')

      Promise.reject(e)
    }
  }

  async getBusinesses() {
    if (this.auth === undefined || this.auth.getters.user === null) {
      return
    }

    if (this.getters.isStoreLoaded && this.getters.businesses.length > 0) {
      return
    }

    const {
      data: { data: businesses },
    } = await getBusinesses(this.auth.getters.user.id, this.getters.businessSlug ?? undefined)

    this.mutations.setBusinesses(businesses)

    if (businesses.length === 1) {
      await this.dispatch('setMainBusiness', businesses[0].slug)
      await this.dispatch('setSessionBusiness', businesses[0].slug)
    }
  }

  async getBusiness(forceUpdate = false) {
    const slug = this.getters.businessSlug
    if (slug === null) {
      return
    }

    // don't fetch business, if data is already stored (unless forceUpdate is true)
    if (!forceUpdate && !!this.getters.businessData.find((b) => b.slug === slug)) {
      return
    }

    try {
      const { data } = await getBusinessInfo(slug)
      await this.dispatch('setBusinessData', transformBusinessResponseToDto(data.data))
      await this.dispatch('setCountry', data.data.country)

      // Set locale using business country data
      if (this.getters.country) {
        this.dashboard?.dispatch('setCountryLocale', this.getters.country)
      }
    } catch {}
  }

  async getBusinessConfig() {
    try {
      if (null === this.getters.mainBusinessId) {
        return
      }

      const { data } = await getBusinessConfig(this.getters.mainBusinessId)
      this.commit('setConfig', data.data)
    } catch (e) {}
  }

  updateBusinessSettings(settings: GeneralSettingsItem[]) {
    this.commit('setSettings', settings)

    if (this.getters.timezoneSetting) {
      this.dashboard?.dispatch('setTimezone', this.getters.timezoneSetting.value)
    }
  }

  async loadBusinessData() {
    try {
      if (null === this.getters.mainBusinessId) {
        return
      }

      const { data } = await getBusinessData(this.getters.mainBusinessId)
      this.commit('setLocations', data.data.locations)
      this.commit('setSettings', data.data.general_settings)
      if (this.getters.timezoneSetting) {
        this.dashboard?.dispatch('setTimezone', this.getters.timezoneSetting.value)
      }
      this.commit('setConfig', data.data.config)
      this.commit('setFeatures', data.data.features)
    } catch (e) {}
  }

  async setBusinessLogo(payload: { businessId: string; logo: FileUploadItemResponseDTO | null }) {
    await this.commit('setBusinessLogo', payload)
  }

  async onBusinessChoose(): Promise<void> {
    if (this.getters.businesses.length > 1) {
      await this.dispatch('loadBusinessStore')
    }
  }

  async setSessionBusiness(slug: string | null) {
    this.mutations.setSessionBusiness(slug)
  }

  async setMainBusiness(slug: string | null) {
    this.mutations.setMainBusiness(slug)
    await this.dispatch('onBusinessChoose')
  }

  setBusinessSlug(slug: string | null) {
    this.mutations.setBusinessSlug(slug)
  }

  async setUserPhoto(photo: FileUploadItemResponseDTO | null) {
    this.commit('setUserPhoto', photo)
    await this.dispatch('getBusinessUser')
  }

  setProfile(profile: BusinessUserProfileResponse) {
    this.commit('setProfile', profile)
  }

  async stopImpersonate() {
    this.mutations.setProfile(null)
    this.dispatch('setSessionBusiness', null)
    this.dispatch('_init')
  }

  setLocations(locations: LocationResponseDto[]) {
    this.mutations.setLocations(locations)
  }

  setBusinessData(business: BusinessDto) {
    this.commit('setBusinessData', business)
  }

  setCountry(country: CommonCountryResponseDto | null) {
    this.commit('setCountry', country)
  }

  async loadBusinessStore() {
    await this.dispatch('getBusiness')
    await Promise.all([this.dispatch('getBusinessUser'), this.dispatch('loadBusinessData')])
  }

  setLocation(location: LocationResponseDto) {
    this.mutations.setLocation(location)
  }
}

export const business = new Module({
  state: ResettableState,
  getters: BusinessGetters,
  mutations: BusinessMutations,
  actions: BusinessActions,
}) as RuntimeModule<ResettableState, BusinessGetters, BusinessMutations, BusinessActions>

business.namespace = 'BusinessModule/'
business.path = ['BusinessModule']

export const useBusiness = createComposable(business)
