import { Actions, createComposable, Getters, Module, Mutations } from 'vuex-smart-module'
import {
  authenticate,
  authenticateSocial,
  currentUser,
  getUserPreferences,
  impersonate,
  refreshTokens,
  revokeToken,
} from '@/api/Auth'
import UserDto, { transformUserItemResponseToDto } from '@/dto/Auth/UserDto'
import PermissionDto from '@/dto/Auth/PermissionDto'
import AuthRepository, { Authentication, PersistedAuthState } from '@/store/Auth/auth.repository'
import { FileUploadItemResponseDTO } from '@/dto/common/files'
import { UserPreferenceResponseDTO } from '@/dto/Auth/Preference/Response'
import { AuthTokenResponse, AuthTokenResponseDto } from '@/dto/Auth/TokensDto'
import { UserPreferenceKeys } from '@/enums/UserPreferenceEnum'
import { RuntimeModule } from '@/store/moduleType'
import { getMyMarketplacePartner } from '@/api/Marketplace/Request'
import { MarketplacePartnerDto } from '@/dto/Marketplace/Partner'

export const enum AuthEvents {
  OnUserAuthenticated = 'onUserAuthenticated',
  OnLogout = 'onLogout',
  OnUserChange = 'onUserChange',
  Initialized = 'initialized',
}

const localAuthStorage = AuthRepository.getLocalInstance()
const sessionAuthStorage = AuthRepository.getSessionInstance()

class AuthState {
  auth: PersistedAuthState = new PersistedAuthState()
  partner: MarketplacePartnerDto = {} as MarketplacePartnerDto
  refreshingTokensPromise: AuthTokenResponse | null = null
  user: UserDto | null = null
  userPreferences: UserPreferenceResponseDTO[] = []
  isStoreLoaded = true
}

class ResettableState {
  authState: AuthState = new AuthState()
}

class AuthGetters extends Getters<ResettableState> {
  get isImpersonating() {
    const persistedAuthState = sessionAuthStorage.state

    return sessionAuthStorage.isStateValid(persistedAuthState)
  }

  get isAdmin() {
    return null !== this.state.authState.user && this.state.authState.user.isAdmin
  }

  get fullName() {
    if (!this.state.authState.user?.profile) {
      return ''
    }
    return `${this.state.authState.user?.profile?.first_name} ${this.state.authState.user?.profile?.last_name}`
  }

  get adminFullName() {
    return this.state.authState.auth.user.fullName
  }

  get accessToken(): string | null {
    return this.state.authState.auth.user.accessToken
  }

  get refreshToken(): string | null {
    return this.state.authState.auth.user.refreshToken
  }

  get isAuthenticated(): boolean {
    return null !== this.getters.accessToken && null !== this.getters.refreshToken
  }

  get user() {
    return this.state.authState.user
  }

  get preferences() {
    return this.state.authState.userPreferences
  }

  get partner() {
    return this.state.authState.partner
  }

  preferenceByKey(key: UserPreferenceKeys) {
    return this.getters.preferences.find((pref) => pref.key === key)?.value ?? undefined
  }

  get abilities() {
    if (this.state.authState.user) {
      if (this.state.authState.user.abilities.length) {
        return this.state.authState.user?.abilities
      }
      const abilities: PermissionDto[] = []

      for (const role of this.state.authState.user.roles) {
        abilities.push(...role.abilities)
      }

      return abilities
    } else {
      return []
    }
  }

  get refreshingTokensPromise() {
    return this.state.authState.refreshingTokensPromise
  }

  get isStoreLoaded(): boolean {
    return this.state.authState.isStoreLoaded
  }

  get getImpersonatorRedirect(): string | undefined {
    return this.state.authState.auth.redirect
  }
}

class AuthMutations extends Mutations<ResettableState> {
  setAuthUser(user: Authentication | null) {
    if (null === user) {
      this.state.authState.auth.user = new Authentication()
    } else {
      this.state.authState.auth.user = user
    }
    localAuthStorage.state = this.state.authState.auth
  }

  setRefreshingTokensPromise(value: AuthTokenResponse | null) {
    this.state.authState.refreshingTokensPromise = value
  }

  setUser(userDto: UserDto | null) {
    this.state.authState.user = userDto
  }

  setPreferences(preferences: UserPreferenceResponseDTO[]) {
    this.state.authState.userPreferences = preferences
  }

  setPartner(partner: MarketplacePartnerDto) {
    this.state.authState.partner = partner
  }

  setImpersonator(impersonator: Authentication | null) {
    if (null === impersonator) {
      this.state.authState.auth.user = new Authentication()
    } else {
      this.state.authState.auth.user = impersonator
    }
    sessionAuthStorage.state = this.state.authState.auth
  }

  setIsStoreLoaded(state: boolean) {
    this.state.authState.isStoreLoaded = state
  }

  setImpersonatorRedirect(redirect?: string) {
    this.state.authState.auth.redirect = redirect

    sessionAuthStorage.state = this.state.authState.auth
  }

  setUserPhoto(photo: FileUploadItemResponseDTO | null) {
    if (this.state.authState.user !== null && this.state.authState.user.profile !== null) {
      this.state.authState.user.profile.photo = photo
    }
  }

  reset() {
    this.state.authState = new AuthState()
  }
}

class AuthActions extends Actions<ResettableState, AuthGetters, AuthMutations, AuthActions> {
  $init(): void {
    this.dispatch('_init')
  }

  async _init() {
    await this.dispatch('setStoreLoaded', false)

    if (null !== localAuthStorage.state && localAuthStorage.isStateValid(localAuthStorage.state)) {
      await this.dispatch('setAuthUser', localAuthStorage.state.user)
    }

    if (
      null !== sessionAuthStorage.state &&
      sessionAuthStorage.isStateValid(sessionAuthStorage.state)
    ) {
      await this.dispatch('setImpersonator', sessionAuthStorage.state.user)
    }

    if (this.getters.isAuthenticated) {
      await this.dispatch(AuthEvents.OnUserAuthenticated)
    }

    await this.dispatch('setStoreLoaded', true)

    this.dispatch(AuthEvents.Initialized)
  }

  _reset() {
    localAuthStorage.state = null
    sessionAuthStorage.state = null
    this.mutations.reset()
  }

  setStoreLoaded(state: boolean) {
    this.mutations.setIsStoreLoaded(state)
  }

  async [AuthEvents.OnUserAuthenticated]() {
    await this.actions.getCurrentUser()
  }

  [AuthEvents.OnUserChange]() {
    return
  }

  [AuthEvents.OnLogout]() {
    return
  }

  [AuthEvents.Initialized]() {
    return
  }

  setImpersonator(impersonator: Authentication | null) {
    this.mutations.setImpersonator(impersonator)
  }

  async getCurrentUser() {
    try {
      await this.dispatch('getUser')
      if (this.getters.isImpersonating) {
        this.mutations.setImpersonator({
          ...this.state.authState.auth.user,
        })
      } else {
        this.mutations.setAuthUser({
          ...this.state.authState.auth.user,
        })
      }
      this.dispatch(AuthEvents.OnUserChange)
    } catch (e) {
      console.log('Error occurred getting current user.')
    }
  }

  async getUser() {
    this.dispatch('getUserPreferences')

    const {
      data: { data: userResponse },
    } = await currentUser()
    const userDto = transformUserItemResponseToDto(userResponse)
    this.mutations.setUser(userDto)
  }

  async getUserPreferences() {
    const {
      data: { data: userPreferences },
    } = await getUserPreferences()
    this.mutations.setPreferences(userPreferences)
  }

  async getMarketplacePartner(businessId: string) {
    const { data } = await getMyMarketplacePartner(businessId)
    this.mutations.setPartner(data.data)
  }

  async updateMarketplacePartner(partner: MarketplacePartnerDto) {
    this.mutations.setPartner(partner)
  }

  async authenticateUsingPassword(payload: { username: string; password: string }) {
    const { data: authentication } = await authenticate(payload.username, payload.password)

    this.dispatch('auth', authentication)
  }

  async authenticateUsingSocial(payload: { provider: string; code: string }) {
    const { data: authentication } = await authenticateSocial(payload.provider, payload.code)

    this.dispatch('auth', authentication)
  }

  auth(authentication: AuthTokenResponseDto) {
    const authUser = new Authentication()
    authUser.accessToken = authentication.access_token
    authUser.refreshToken = authentication.refresh_token
    this.dispatch('setAuthUser', authUser)
    this.dispatch(AuthEvents.OnUserAuthenticated)
  }

  async impersonate(payload: { userId: string; redirect?: string }) {
    const { data } = await impersonate(payload.userId)

    const impersonator = new Authentication()
    impersonator.accessToken = data.access_token
    impersonator.refreshToken = data.refresh_token
    impersonator.fullName = this.getters.fullName

    this.dispatch('setImpersonator', impersonator)
    this.mutations.setImpersonatorRedirect(payload.redirect)
    this.dispatch(AuthEvents.OnUserAuthenticated)
  }

  async stopImpersonate() {
    this.dispatch('setImpersonator', null)
    this.dispatch('_init')
  }

  _makeRefreshTokensApiCall(refreshToken: string): AuthTokenResponse {
    return refreshTokens(refreshToken)
  }

  async refreshTokens() {
    if (this.getters.refreshToken === null) {
      throw new Error('Unauthenticated.')
    }

    if (this.getters.refreshingTokensPromise === null) {
      const call = this.dispatch('_makeRefreshTokensApiCall', this.getters.refreshToken)
      this.commit('setRefreshingTokensPromise', call as AuthTokenResponse)
    }

    if (this.getters.refreshingTokensPromise) {
      try {
        const { data } = await this.getters.refreshingTokensPromise
        const authUser = new Authentication()
        authUser.accessToken = data.access_token
        authUser.refreshToken = data.refresh_token
        this.dispatch('setAuthUser', authUser)
      } catch (e) {
      } finally {
        this.commit('setRefreshingTokensPromise', null)
      }
    }
  }

  setAuthUser(user: Authentication) {
    this.mutations.setAuthUser(user)
  }

  async logout() {
    try {
      await revokeToken()
    } catch (e) {
    } finally {
      await this.dispatch('_reset')
      await this.dispatch(AuthEvents.OnLogout)
    }
  }

  async setUserPhoto(photo: FileUploadItemResponseDTO | null) {
    this.commit('setUserPhoto', photo)
    await this.dispatch('getUser')
  }

  async setUser(user: UserDto) {
    this.commit('setUser', user)
    await this.dispatch('getUser')
  }
}

export const auth = new Module({
  state: ResettableState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions,
}) as RuntimeModule<ResettableState, AuthGetters, AuthMutations, AuthActions>

auth.namespace = 'AuthModule/'
auth.path = ['AuthModule']

export const useAuth = createComposable(auth)
