import client, { AbortableAxiosRequestConfig } from '@/api/client'
import { AxiosResponse } from 'axios'
import { ResponseOne } from '@/api/PaginatedResponse'
import { BusinessDataResponseDto } from '@/dto/Business/BusinessData/Response'

export function getBusinessData(
  businessId: string,
): Promise<AxiosResponse<ResponseOne<BusinessDataResponseDto>>> {
  return client.get(`/business/businesses/${businessId}/business-data`, <AbortableAxiosRequestConfig>{
    _cannotAbort: true,
  })
}
