import { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { AbilityEnum } from '@/enums/AbilityEnum'
import { RouteList } from '@/routes/RouteList'
import { defineComponent, h } from 'vue'

const NavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')

const MarketplacePublic = {
  PartnersList: () => import('@/views/Marketplace/Public/PartnersListView.vue'),
  PartnerSingle: () => import('@/views/Marketplace/Public/PartnerSingleView.vue'),
  EmailsList: () => import('@/views/Marketplace/Public/EmailsListView.vue'),
  EmailSingle: () => import('@/views/Marketplace/Public/EmailSingleView.vue'),
}

const MarketplaceSubscriptions = {
  PartnersList: () => import('@/views/Marketplace/Subscriptions/PartnersListView.vue'),
  PartnerSingle: () => import('@/views/Marketplace/Subscriptions/PartnerSingleView.vue'),
  EmailsList: () => import('@/views/Marketplace/Subscriptions/EmailsListView.vue'),
  EmailSingle: () => import('@/views/Marketplace/Subscriptions/EmailSingleView.vue'),
  Subscribe: () => import('@/views/Marketplace/Subscriptions/SubscribeView.vue'),
}

const MarketplaceSettings = {
  ProfileIndex: () => import('@/views/Marketplace/Settings/Profile/ProfileIndexView.vue'),
  ProfileProfile: () => import('@/views/Marketplace/Settings/Profile/ProfileProfileView.vue'),
  ProfileConfig: () => import('@/views/Marketplace/Settings/Profile/ProfileConfigView.vue'),
  EmailsList: () => import('@/views/Marketplace/Settings/EmailsListView.vue'),
  EmailSingle: () => import('@/views/Marketplace/Settings/EmailSingleView.vue'),
  EmailCreate: () => import('@/views/Marketplace/Settings/EmailCreateView.vue'),
  EmailEdit: () => import('@/views/Marketplace/Settings/EmailCreateView.vue'),
  Subscribers: () => import('@/views/Marketplace/Settings/SubscribersView.vue'),
  BecomePartner: () => import('@/views/Marketplace/Settings/BecomePartnerView.vue'),
}

export default <RouteRecordRaw[]>[
  {
    path: RouteList.MARKETPLACE.INDEX.PATH,
    name: RouteList.MARKETPLACE.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Marketplace,
        title: 'Marketplace',
      },
    },
    meta: {
      // TODO - check these
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.MARKETPLACE],
      permission: [AbilityEnum.MARKETPLACE],
    },
    children: [
      {
        path: RouteList.MARKETPLACE.INDEX.PATH,
        name: RouteList.MARKETPLACE.INDEX.NAME,
        component: defineComponent({ render: () => h('div') }),
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
        redirect: {
          name: RouteList.MARKETPLACE.PUBLIC.PARTNERS.INDEX.NAME,
        },
      },
      {
        path: RouteList.MARKETPLACE.PUBLIC.PARTNERS.INDEX.PATH,
        name: RouteList.MARKETPLACE.PUBLIC.PARTNERS.INDEX.NAME,
        component: MarketplacePublic.PartnersList,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.PUBLIC.PARTNERS.PARTNER.PATH,
        name: RouteList.MARKETPLACE.PUBLIC.PARTNERS.PARTNER.NAME,
        component: MarketplacePublic.PartnerSingle,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.PUBLIC.EMAILS.INDEX.PATH,
        name: RouteList.MARKETPLACE.PUBLIC.EMAILS.INDEX.NAME,
        component: MarketplacePublic.EmailsList,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.PUBLIC.EMAILS.EMAIL.PATH,
        name: RouteList.MARKETPLACE.PUBLIC.EMAILS.EMAIL.NAME,
        component: MarketplacePublic.EmailSingle,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.SUBSCRIPTIONS.PARTNERS.INDEX.PATH,
        name: RouteList.MARKETPLACE.SUBSCRIPTIONS.PARTNERS.INDEX.NAME,
        component: MarketplaceSubscriptions.PartnersList,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.SUBSCRIPTIONS.PARTNERS.PARTNER.PATH,
        name: RouteList.MARKETPLACE.SUBSCRIPTIONS.PARTNERS.PARTNER.NAME,
        component: MarketplaceSubscriptions.PartnerSingle,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.SUBSCRIPTIONS.EMAILS.INDEX.PATH,
        name: RouteList.MARKETPLACE.SUBSCRIPTIONS.EMAILS.INDEX.NAME,
        component: MarketplaceSubscriptions.EmailsList,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.SUBSCRIPTIONS.EMAILS.EMAIL.PATH,
        name: RouteList.MARKETPLACE.SUBSCRIPTIONS.EMAILS.EMAIL.NAME,
        component: MarketplaceSubscriptions.EmailSingle,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.SUBSCRIPTIONS.SUBSCRIBE.PATH,
        name: RouteList.MARKETPLACE.SUBSCRIPTIONS.SUBSCRIBE.NAME,
        component: MarketplaceSubscriptions.Subscribe,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
        },
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.PROFILE.INDEX.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.PROFILE.INDEX.NAME,
        component: MarketplaceSettings.ProfileIndex,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: true,
        },
        redirect: {
          name: RouteList.MARKETPLACE.SETTINGS.PROFILE.PROFILE.NAME,
        },
        children: [
          {
            path: RouteList.MARKETPLACE.SETTINGS.PROFILE.PROFILE.PATH,
            name: RouteList.MARKETPLACE.SETTINGS.PROFILE.PROFILE.NAME,
            component: MarketplaceSettings.ProfileProfile,
            meta: {
              feature: [FeatureEnum.MARKETPLACE],
              permission: [AbilityEnum.MARKETPLACE],
            },
          },
          {
            path: RouteList.MARKETPLACE.SETTINGS.PROFILE.CONFIGURATION.PATH,
            name: RouteList.MARKETPLACE.SETTINGS.PROFILE.CONFIGURATION.NAME,
            component: MarketplaceSettings.ProfileConfig,
            meta: {
              feature: [FeatureEnum.MARKETPLACE],
              permission: [AbilityEnum.MARKETPLACE],
            },
          },
        ],
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.EMAILS.INDEX.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.EMAILS.INDEX.NAME,
        component: MarketplaceSettings.EmailsList,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: true,
        },
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.EMAILS.EMAIL.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.EMAILS.EMAIL.NAME,
        component: MarketplaceSettings.EmailSingle,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: true,
        },
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.EMAILS.CREATE.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.EMAILS.CREATE.NAME,
        component: MarketplaceSettings.EmailCreate,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: true,
        },
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.EMAILS.EDIT.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.EMAILS.EDIT.NAME,
        component: MarketplaceSettings.EmailCreate,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: true,
        },
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.SUBSCRIBERS.INDEX.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.SUBSCRIBERS.INDEX.NAME,
        component: MarketplaceSettings.Subscribers,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: true,
        },
      },
      {
        path: RouteList.MARKETPLACE.SETTINGS.FAQ.PATH,
        name: RouteList.MARKETPLACE.SETTINGS.FAQ.NAME,
        component: MarketplaceSettings.BecomePartner,
        meta: {
          feature: [FeatureEnum.MARKETPLACE],
          permission: [AbilityEnum.MARKETPLACE],
          requiresPartner: false,
        },
      },
    ],
  },
]
