export default abstract class Repository<T> {
  private storage: Storage

  protected constructor(storage: Storage) {
    this.storage = storage
  }

  public get state(): T | null {
    const state = JSON.parse(<string>this.storage.getItem(this.storageKey))

    return !this.isStateValid(state) ? null : state
  }

  public set state(state: T | null) {
    if (!this.isStateValid(state)) {
      this.storage.removeItem(this.storageKey)
    } else {
      this.storage.setItem(this.storageKey, JSON.stringify(state))
    }
  }

  protected abstract get storageKey(): string

  public abstract isStateValid(state: T | null): boolean
}
