<template>
  <slot :modal-id="modalId" name="modal-button" />
  <Teleport to=".modals-container">
    <div :id="modalId" ref="modalEl" class="modal fade text-secondary" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title fw-bolder">
              {{ title }}
            </h4>
            <button
              type="button"
              class="btn btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <slot name="modal-body">
              <div class="d-flex align-items-center">
                <BasePortalIcon
                  icon="trash"
                  class="text-danger"
                  width="1.125rem"
                  height="1.125rem"
                />
                <span class="ms-3">
                  {{
                    item === null
                      ? t(`Are you sure you want to permanently ${buttonAction} this?`)
                      : t(`Are you sure you want to permanently ${buttonAction} `) + item + '?'
                  }}
                </span>
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <div class="w-100">
              <div class="row g-3 justify-content-end">
                <slot name="modal-action-buttons">
                  <div class="col-5">
                    <div class="d-grid">
                      <BaseSubmitButton
                        type="button"
                        btn-class="btn btn-danger rounded-pill"
                        :is-submitting="isSubmitting"
                        :text="buttonTitle"
                        @click.prevent="onConfirm"
                      />
                    </div>
                  </div>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, onBeforeUnmount, onMounted, onUpdated, ref, Ref, toRef, watch } from 'vue'
import Modal from 'bootstrap/js/dist/modal'
import uid from '@/helpers/uid'
import waitFor from '@/helpers/awaitValue.helper'

const emits = defineEmits<{
  (e: 'confirm'): void
  (e: 'hidden'): void
}>()

const props = defineProps({
  visible: {
    default: false,
    type: Boolean,
  },
  title: {
    type: String,
    default: '',
  },
  buttonTitle: {
    type: String,
    default: 'Delete',
    required: false,
  },
  item: {
    type: String,
    default: null,
  },
  delete: {
    type: Boolean,
    default: true,
  },
  autoClose: {
    type: Boolean,
    default: true,
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const isVisible: Ref<boolean> = toRef(props, 'visible')
const modalEl: Ref<HTMLElement | undefined> = ref()
const modalId = `bs-modal-${uid.seq()}`
let modalInstance: Modal | null = null
const isModalVisible = ref<boolean>(false)

const buttonAction = computed(() => {
  return props.buttonTitle.toLocaleLowerCase()
})

const onConfirm = () => {
  emits('confirm')

  if (props.autoClose) {
    hideModal()
  }
}

const showModal = () => {
  modalInstance?.show()
}

const hideModal = () => {
  modalInstance?.hide()
}

const hiddenListener = () => {
  isModalVisible.value = false
  emits('hidden')
}

const showListener = () => {
  isModalVisible.value = true

  // set background red
  if (props.delete)
    window.setTimeout(
      () => document.getElementsByClassName('modal-backdrop')[0].classList.add('bg-danger'),
      0,
    )
}

watch(isVisible, (newValue: boolean, oldValue: boolean) => {
  if (oldValue && !newValue) {
    hideModal()
  }
  if (!oldValue && newValue) {
    showModal()
  }
})

onMounted(() => {
  if (!modalEl.value) {
    return
  }

  modalInstance = new Modal(modalEl.value, {
    backdrop: 'static',
    keyboard: false,
    focus: true,
  })

  modalEl.value.addEventListener('show.bs.modal', showListener)
  modalEl.value.addEventListener('hidden.bs.modal', hiddenListener)
})

onUpdated(() => {
  modalInstance?.handleUpdate()
})

onBeforeUnmount(async () => {
  // Hide the modal first, because it keeps locking the window if the user clicks back
  // in navigation rather than closing the modal first an the navigating away
  hideModal()

  // Wait for the modal to be hidden before disposing it or else the error will be thrown.
  // This happens because the modal is hidden only when all the transitions are finished and
  // it depends on the duration of the transitions (this may change), so hideModal() may take
  // some time to run while modalInstance?.dispose() runs immediatelly after and that cannot happen.
  await waitFor(() => !isModalVisible.value)

  modalEl.value?.removeEventListener('show.bs.modal', showListener)
  modalEl.value?.removeEventListener('hidden.bs.modal', hiddenListener)
  modalInstance?.dispose()
})
</script>
