<template>
  <div v-if="props.value" class="badge bg-opacity-10 text-uppercase bg-success text-success">
    {{ t('Yes') }}
  </div>
  <div v-else class="badge bg-opacity-10 text-uppercase bg-danger text-danger">
    {{ t('No') }}
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  value: {
    required: true,
    type: Boolean,
  },
})

const { t } = useI18n()

</script>
