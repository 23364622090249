import { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'

const PublicNavBar = () => import('@/components/Layout/PublicNavBar.vue')
const DocumentSignNavBar = () =>
  import('@/components/Public/IntelliForms/Layout/DocumentSignNavBar.vue')
const IntelliFormsSideBar = () =>
  import('@/components/Public/IntelliForms/Layout/IntelliFormsSideBar.vue')
const IntelliFormsRegisterView = () =>
  import('@/views/Public/IntelliForms/IntelliFormsRegisterView.vue')
const IntelliFormsSignView = () => import('@/views/Public/IntelliForms/IntelliFormsSignView.vue')
const IntelliFormsDocumentView = () =>
  import('@/views/Public/IntelliForms/IntelliFormsDocumentView.vue')
const IntelliFormsPaymentView = () =>
  import('@/views/Public/IntelliForms/IntelliFormsPaymentView.vue')
const IntelliFormsLinkExpiredView = () =>
  import('@/views/Public/IntelliForms/IntelliFormsLinkExpiredView.vue')
const PublicErrorView = () => import('@/views/Public/Error/PublicErrorView.vue')

export default <RouteRecordRaw[]>[
  {
    path: RouteList.PUBLIC.INTELLIFORMS.REGISTER.PATH,
    name: RouteList.PUBLIC.INTELLIFORMS.REGISTER.NAME,
    components: {
      default: IntelliFormsRegisterView,
      NavBar: PublicNavBar,
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: RouteList.PUBLIC.INTELLIFORMS.START.PATH,
    name: RouteList.PUBLIC.INTELLIFORMS.START.NAME,
    components: {
      default: IntelliFormsSignView,
      NavBar: DocumentSignNavBar,
      SideBar: IntelliFormsSideBar,
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: RouteList.PUBLIC.INTELLIFORMS.DOCUMENT.PATH,
    name: RouteList.PUBLIC.INTELLIFORMS.DOCUMENT.NAME,
    components: {
      default: IntelliFormsDocumentView,
      NavBar: DocumentSignNavBar,
      SideBar: IntelliFormsSideBar,
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: RouteList.PUBLIC.INTELLIFORMS.PAYMENT.PATH,
    name: RouteList.PUBLIC.INTELLIFORMS.PAYMENT.NAME,
    components: {
      default: IntelliFormsPaymentView,
      NavBar: DocumentSignNavBar,
      SideBar: IntelliFormsSideBar,
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: RouteList.PUBLIC.INTELLIFORMS.EXPIRED.PATH,
    name: RouteList.PUBLIC.INTELLIFORMS.EXPIRED.NAME,
    components: {
      default: IntelliFormsLinkExpiredView,
      NavBar: PublicNavBar,
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: RouteList.PUBLIC.INTELLIFORMS.NOT_FOUND.PATH,
    name: RouteList.PUBLIC.INTELLIFORMS.NOT_FOUND.NAME,
    components: {
      default: PublicErrorView,
      NavBar: PublicNavBar,
    },
    meta: {
      requiresAuth: false,
    },
    props: {
      default: {
        errorCode: 404,
      },
    },
  },
]
