<template>
    <div class="d-flex align-items-center gap-2">
        <ejs-button>Button</ejs-button>
        <ejs-button @click="triggerError">Trigger Error</ejs-button>
    </div>
</template>

<script lang="ts" setup>
    import { ButtonComponent as EjsButton } from "@syncfusion/ej2-vue-buttons"

    const triggerError = () => {
        throw new Error('For testing sentry')
    }
</script>