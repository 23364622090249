import { Router, RouteLocationNormalized, LocationQueryValue } from 'vue-router'

export function getTagsFromUrl(url = window.location.href as string): string[] {
  const key = 'tagIn'

  try {
    const searchParams = new URLSearchParams(url.slice(url.indexOf('?')))
    if (searchParams.has(key)) {
      return searchParams.getAll(key)
    }
    return []
  } catch (e) {
    return [] // if invalid url is provided
  }
}

export function deleteQueryParam(
  router: Router,
  route: RouteLocationNormalized,
  paramName: string,
) {
  const newQuery = Object.assign({}, route.query)
  delete newQuery[paramName]
  router.replace({ ...route, query: newQuery })
}

export function getBusinessSlugFromUrl() {
  const match = window.location.href.match(/\/b\/([^\/]*)\//)
  return match ? match[1] : null
}

export function getArrayFromQuery(
  query: LocationQueryValue | LocationQueryValue[],
): LocationQueryValue[] | undefined {
  if (!query) {
    return undefined
  }

  if (typeof query === 'string') {
    return [query]
  }

  return query
}
