import Repository from '@/store/repository'

export class Authentication {
  accessToken: string | null = null
  refreshToken: string | null = null
  fullName: string | null = null
}

export class PersistedAuthState {
  user: Authentication = new Authentication()
  redirect?: string = undefined
}

export default class AuthRepository extends Repository<PersistedAuthState> {
  private static localInstance?: AuthRepository
  private static sessionInstance?: AuthRepository

  protected get storageKey(): string {
    return 'auth_state'
  }

  public static getLocalInstance(): AuthRepository {
    if (undefined === AuthRepository.localInstance) {
      AuthRepository.localInstance = new AuthRepository(window.localStorage)
    }

    return AuthRepository.localInstance
  }

  public static getSessionInstance(): AuthRepository {
    if (undefined === AuthRepository.sessionInstance) {
      AuthRepository.sessionInstance = new AuthRepository(window.sessionStorage)
    }

    return AuthRepository.sessionInstance
  }

  public isStateValid(persistedAuthState: PersistedAuthState | null) {
    return (
      null !== persistedAuthState &&
      null !== persistedAuthState.user.accessToken &&
      null !== persistedAuthState.user.refreshToken
    )
  }

  public getRedirect(): string | undefined {
    return this.state?.redirect
  }
}
