<template>
  <button :type="type" :class="btnClass" :disabled="isSubmitting || disabled">
    <span
      v-if="isSubmitting"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <span v-if="!isSubmitting && !$slots.default">{{ text || t('Save') }}</span>
    <span v-if="!isSubmitting && $slots.default"><slot name="default" /></span>
  </button>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  btnClass: {
    type: String,
    default: 'btn btn-primary rounded-pill',
  },
  text: {
    type: String,
    default: '',
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<'submit' | 'reset' | 'button'>,
    default: 'submit',
  },
})
</script>
