export default {
  AUTH: {
    LOGIN: {
      NAME: 'login',
      PATH: '/login',
    },
    LOGOUT: {
      NAME: 'logout',
      PATH: '/logout',
    },
    PASSWORD: {
      FORGOT_PASSWORD: {
        NAME: 'auth.password.forgot',
        PATH: '/auth/password/forgot',
      },
      RESET_PASSWORD: {
        NAME: 'auth.password.reset',
        PATH: '/auth/password/reset',
      },
    },
    INVITATIONS: {
      USER: {
        NAME: 'user.invitation',
        PATH: '/invitations/:invitationId',
      },
      ADMIN: {
        NAME: 'admin.invitation',
        PATH: '/admin/invitations/:invitationId',
      },
    },
    BUSINESS_CHOICE: {
      NAME: 'business-choice',
      PATH: '/business-choice',
    },
    REGISTRATION: {
      STEPS: {
        USER: {
          NAME: 'register.step.user',
          PATH: '/register',
        },
        BUSINESS: {
          NAME: 'register.step.business',
          PATH: '/register/business',
        },
        LOCATIONS: {
          NAME: 'register.step.locations',
          PATH: '/register/locations',
        },
        COMPANY: {
          NAME: 'register.step.company',
          PATH: '/register/company',
        },
        TERMS: {
          NAME: 'register.step.terms',
          PATH: '/register/terms',
        },
        PAYMENT: {
          NAME: 'register.step.confirm',
          PATH: '/register/confirm',
        },
      },
    },
  },
}
