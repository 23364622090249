<template>
  <base-confirm-modal :visible="isReloadModalVisible" :delete="false">
    <template #modal-body>
      <div>{{ t('Page content has been renewed. Please reload the page.') }}</div>
    </template>
    <template #modal-action-buttons>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary rounded-pill" @click="reloadPage">{{ t('Reload') }}</button>
      </div>
    </template>
  </base-confirm-modal>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useReloadModal } from '@/composables/useReloadModal'

const { t } = useI18n()
const { isReloadModalVisible } = useReloadModal()

const reloadPage = () => location.reload()
</script>
