import { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'
import { MenuCode } from '@/shared'

const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const BackOfficeNavBar = () =>
  import('@/components/Layout/BackOffice/NavBar.vue')

const EditAdminUserProfile = () =>
  import('@/components/BackOffice/UserSettings/EditAdminUserProfile.vue')
const EditAdminUserNotifications = () =>
  import('@/components/BackOffice/UserSettings/EditAdminUserNotifications.vue')
const EditAdminUserContacts = () =>
  import('@/components/BackOffice/UserSettings/EditAdminUserContacts.vue')
const EditAdminUserPassword = () =>
  import('@/components/BackOffice/UserSettings/EditAdminUserPassword.vue')
const EditAdminUserPreferences = () =>
  import('@/components/BackOffice/UserSettings/EditAdminUserPreferences.vue')
const AdminIntegrations = {
    Index: () => import('@/views/Integrations/AdminUserIntegrationsView.vue'),
    Setup: () => import('@/views/Integrations/AdminUserIntegrationSetup/AdminUserIntegrationSetupView.vue'),
    Google: () => import('@/views/Integrations/AdminUserIntegrationSetup/AdminUserIntegrationSetupGoogleView.vue'),
    Microsoft: () => import('@/views/Integrations/AdminUserIntegrationSetup/AdminUserIntegrationSetupMicrosoftView.vue'),
    Zoom: () => import('@/views/Integrations/AdminUserIntegrationSetup/AdminUserIntegrationSetupZoomView.vue'),
    Facebook: () => import('@/views/Integrations/AdminUserIntegrationSetup/AdminUserIntegrationSetupFacebookView.vue'),
}

export default <RouteRecordRaw[]>[
  {
    path: RouteList.BACK_OFFICE.USER_SETTINGS.INDEX.PATH,
    name: RouteList.BACK_OFFICE.USER_SETTINGS.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar: BackOfficeNavBar,
    },
    meta: {
      requiresAuth: true,
      admin: true,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.AdminUserSettings,
        title: 'User Settings',
      },
    },
    children: [
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.PROFILE.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.PROFILE.NAME,
        component: EditAdminUserProfile,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.NOTIFICATIONS.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.NOTIFICATIONS.NAME,
        component: EditAdminUserNotifications,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.CONTACTS.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.CONTACTS.NAME,
        component: EditAdminUserContacts,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.PASSWORD.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.PASSWORD.NAME,
        component: EditAdminUserPassword,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.PREFERENCES.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.PREFERENCES.NAME,
        component: EditAdminUserPreferences,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.INDEX.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.INDEX.NAME,
        component: AdminIntegrations.Index,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.SETUP.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.SETUP.NAME,
        component: AdminIntegrations.Setup,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.GOOGLE.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.GOOGLE.NAME,
        component: AdminIntegrations.Google,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.MICROSOFT.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.MICROSOFT.NAME,
        component: AdminIntegrations.Microsoft,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.ZOOM.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.ZOOM.NAME,
        component: AdminIntegrations.Zoom,
      },
      {
        path: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.FACEBOOK.PATH,
        name: RouteList.BACK_OFFICE.USER_SETTINGS.INTEGRATIONS.FACEBOOK.NAME,
        component: AdminIntegrations.Facebook,
      },
    ],
  },
]
