import _ from 'lodash'
import { App } from 'vue'

// Used to import BaseComponents globally in every vue SFC
export default {
  install(app: App<Element>) {
    const componentFiles = import.meta.glob(
      '@/components/BaseComponents/**/*.vue',
      { eager: true },
    )

    for (const path in componentFiles) {
      const module: any = componentFiles[path]
      const componentName = _.upperFirst(
        _.camelCase(
          path
            .split('/')
            .pop()
            ?.replace(/\.\w+$/, '') as string,
        ),
      )
      app.component(`${componentName}`, module.default)
    }
  },
}
