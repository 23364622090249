<template>
  <BaseFormFieldLayout
    :id="id"
    :label="label"
    :note="note"
    :error-message="errorMessage"
    :field-class="fieldClass"
  >
    <TimezonesSelect
      v-model="refValue"
      v-bind="$attrs"
      :class="{ 'is-invalid': errorMessage }"
      @update:model-value="$emit('input', $event)"
    />
  </BaseFormFieldLayout>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'

import { asyncComponentWrapper } from '@/composables/useReloadModal'
const TimezonesSelect = asyncComponentWrapper(() => import('@/components/Misc/TimezonesSelect.vue'))

const props = defineProps({
  id: {
    type: [String || Number],
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  note: {
    type: String,
    default: '',
  },
  errorMessage: {
    type: String,
    default: '',
  },
  fieldClass: {
    type: String,
    default: '',
  },
  value: {
    type: [String, undefined] as PropType<string | undefined>,
    default: undefined,
  },
})

const refValue = ref(props.value)

defineEmits<{
  (e: 'input', value: string): void
}>()
</script>
