<template>
  <div ref="widgetHtml"></div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useAuth } from '@/store/Auth'
import { getOnRampWidget } from '@/api/Business/OnRamp/integrationsOnRamp'

const authStore = useAuth()
const widgetHtml = ref(null)

const loadWidget = async () => {
  const userEmail = authStore.getters.user?.email

  if (!userEmail) {
    return
  }

  try {
    const config = {
      organization: '5f84f4ca-d4e9-4c02-a926-ab5c3a31d61c',
      user: userEmail,
      scheme: 'plaintext',
    }

    const response = await getOnRampWidget(config)
    
    if (response.data) {
      widgetHtml.value.innerHTML = response.data
    }
  } catch (error) {
    console.error('Failed to load OnRamp widget:', error)
  }
}

onMounted(() => {
  loadWidget()
})
</script>