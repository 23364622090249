import { Component } from 'vue'
import {
  IntelliFormLeadDocumentStatusEnum,
  IntelliFormLeadPackageStatusEnum,
} from '@/enums/IntelliformEnum'
import { MerchantBusinessTypeEnum } from '@/enums/FinixEnum'
import { asyncComponentWrapper } from '@/composables/useReloadModal'
import { CommonCountryResponseDto } from '@/dto/common/Country/Response/index.js'

const SelectDataField = asyncComponentWrapper(
  () => import('@/components/DataField/SelectDataField.vue'),
)
const CheckboxDataField = asyncComponentWrapper(
  () => import('@/components/DataField/CheckboxDataField.vue'),
)
const CheckboxesDataField = asyncComponentWrapper(
  () => import('@/components/DataField/CheckboxesDataField.vue'),
)
const TextDataField = asyncComponentWrapper(
  () => import('@/components/DataField/TextDataField.vue'),
)
const TextareaDataField = asyncComponentWrapper(
  () => import('@/components/DataField/TextareaDataField.vue'),
)
const DateDataField = asyncComponentWrapper(
  () => import('@/components/DataField/DateDataField.vue'),
)
const DateDataFieldWithoutLabel = asyncComponentWrapper(
  () => import('@/components/DataField/DateDataFieldWithoutLabel.vue'),
)
const DateRangeDataFieldWithoutLabel = asyncComponentWrapper(
  () => import('@/components/DataField/DateRangeDataFieldWithoutLabel.vue'),
)
const DateTimeDataField = asyncComponentWrapper(
  () => import('@/components/DataField/DateTimeDataField.vue'),
)
const DateTimeDataFieldWithoutLabel = asyncComponentWrapper(
  () => import('@/components/DataField/DateTimeDataFieldWithoutLabel.vue'),
)
const ScheduleDataField = asyncComponentWrapper(
  () => import('@/components/DataField/ScheduleDataField.vue'),
)
const PhoneDataField = asyncComponentWrapper(
  () => import('@/components/DataField/PhoneDataField.vue'),
)
const AddressDataField = asyncComponentWrapper(
  () => import('@/components/DataField/AddressDataField.vue'),
)
// const SearchAddressField = asyncComponentWrapper(
//   () => import('@/components/Misc/AdvancedSearch/SearchAddressField.vue')
// )
const EmailTemplateAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/EmailTemplateAction.vue'
    ),
)
const FacebookTemplateAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/FacebookTemplateAction.vue'
    ),
)
const TaskTemplateAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/TaskTemplateAction.vue'
    ),
)
const ChangeActiveTaskStatusAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/ChangeActiveTaskStatusAction.vue'
    ),
)
const ResolveLeadStatusAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/ResolveLeadStatusAction.vue'
    ),
)
const DeleteAllScheduledWorkflowAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/DeleteAllScheduledWorkflowAction.vue'
    ),
)
const ScheduleAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/ChooseScheduleAction.vue'
    ),
)
const UpdateScheduleStatusAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/UpdateScheduleStatusAction.vue'
    ),
)
const EmailToCustomRecipientTemplateAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/EmailToCustomRecipientTemplateAction.vue'
    ),
)
const SmsToCustomRecipientTemplateAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/SmsToCustomRecipientTemplateAction.vue'
    ),
)
const SmsTemplateAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/SmsTemplateAction.vue'
    ),
)
const PushToProcareModerationEngineAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/PushToProcareModerationEngineAction.vue'
    ),
)
const PushToProcareOnlineAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/PushToProcareOnlineAction.vue'
    ),
)
const PushToFamlyAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/PushToFamlyAction.vue'
    ),
)
const PushToKangarootimeAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/PushToKangarootimeAction.vue'
    ),
)
const DataFieldAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/DataFieldAction.vue'
    ),
)
const ChildDataFieldAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/ChildDataFieldAction.vue'
    ),
)
const InSelectOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/InSelectOperator.vue'
    ),
)
const InPhoneNumbersOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/InPhoneNumbersOperator.vue'
    ),
)
const InLocationOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/InLocationOperator.vue'
    ),
)
const BetweenAgeOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/BetweenAgeOperator.vue'
    ),
)
const EqualCheckboxOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/EqualCheckboxOperator.vue'
    ),
)
const EqualTextOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/EqualTextOperator.vue'
    ),
)

const EqualNumberOperator = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/Operators/EqualNumberOperator.vue'
    ),
)

const LeadDataFieldTrigger = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/CriteriaGroupTrigger/LeadDataFieldTrigger.vue'
    ),
)
const LeadChildDataFieldTrigger = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/CriteriaGroupTree/CriteriaGroupTrigger/LeadChildDataFieldTrigger.vue'
    ),
)
const CreateLeadPackageAction = asyncComponentWrapper(
  () =>
    import(
      '@/components/Marketing/CreateWorkflow/ActionTree/ActionTreeElement/CreateLeadPackageAction.vue'
      ),
)

export enum DataFieldName {
  FirstName = 'first_name',
  LastName = 'last_name',
  Type = 'type',
  Value = 'value',
  Source = 'source',
  LocationId = 'location_id',
  Hot = 'hot',
  Language = 'language',
  Relationship = 'relationship',
  Birthday = 'birthday',
  Gender = 'gender',
  StartDate = 'start_date',
  Phone = 'phone',
  PhoneExt = 'phone_extension',
  PhoneType = 'phone_type',
  PhoneConsent = 'phone_consent',
  Email = 'email',
  EmailConsent = 'email_consent',
  EmailType = 'email_type',
  Title = 'title',
  Address = 'address',
  Score = 'score',
  Name = 'name',
  Website = 'website',
  BusinessType = 'business_type',
  CompanyType = 'company_type',
  RegistrationNumber = 'registration_number',
  FacebookName = 'facebook_name',
  FacebookId = 'facebook_psid',
  FacebookPicture = 'facebook_profile_picture',
  FacebookLastMessage = 'facebook_last_message_at',
  FacebookLastDelivery = 'facebook_last_delivery_at',
  FacebookLastRead = 'facebook_last_read_at',
  Facebook = 'facebook',
  ChildAge = 'child_age',
  WaitlistSince = 'waitlist_since',
  UtmSource = 'utm_source',
  UtmMedium = 'utm_medium',
  UtmCampaign = 'utm_campaign',
  UtmTerm = 'utm_term',
  UtmContent = 'utm_content',
}

//todo: move data field related data to separate file
export enum CompanyTypeValues {
  Public = 'public',
  Private = 'private',
}

export enum BusinessTypeValues {
  SoleProprietorship = 'sole_proprietorship',
  Partnership = 'partnership',
  Corporation = 'corporation',
  CoOperative = 'co_operative',
  LLC = 'llc',
  NonProfit = 'non-profit',
}

export enum DataFieldRelatedEntity {
  Lead = 1,
  LeadContact = 2,
  LeadContactChannel = 3,
  LeadChild = 4,
}

// used in advanced search, keys should match keys in DataFieldRelatedEntity
export enum SearchGroupTitle {
  Lead = 'Lead',
  LeadContact = 'Contact',
  LeadContactChannel = 'Contact Channel',
  LeadChild = 'Child',
}

export enum MenuCode {
  Lms = 'lms',
  Ems = 'ems',
  Settings = 'settings',
  BackOffice = 'back-office',
  UserSettings = 'user-settings',
  AdminUserSettings = 'admin-user-settings',
  Marketplace = 'marketplace',
  Website = 'website',
  Task = 'task',
}

// if new type is used in Workflows operators, make sure to modify DataFieldTypeToOperatorMap also
// operators are comparisons like "equal", "greater", "in", "not_in", etc...
export enum DataFieldType {
  Select = 'Select',
  Text = 'Text',
  Textarea = 'Textarea',
  Location = 'Location',
  Status = 'Status',
  BusinessUser = 'BusinessUser',
  Source = 'Source',
  Email = 'Email',
  EmailType = 'EmailType',
  Phone = 'Phone',
  PhoneType = 'PhoneType',
  PhoneNumber = 'PhoneNumber', // workflow specific
  CheckBox = 'Checkbox',
  Checkboxes = 'Checkboxes',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  Gender = 'Genders',
  Language = 'Language',
  Relationship = 'Relationship',
  PhoneConsent = 'PhoneConsent',
  PhoneExtension = 'PhoneExtension',
  EmailConsent = 'EmailConsent',
  Url = 'Url',
  Utm = 'UTM',
  Address = 'Address',
  CompanyType = 'CompanyType',
  Schedule = 'Schedule',
  TaskStatus = 'TaskStatus',
  ScheduleStatus = 'ScheduleStatus',
  UpdateScheduleStatus = 'UpdateScheduleStatus',
  ScheduleField = 'ScheduleField',
  DeletedReason = 'DeletedReason',
  LeadLink = 'LeadLink',
  Age = 'Age',
  ChildAge = 'ChildAge',
  ChildClassroom = 'Classroom',
  IfLeadPackageStatus = 'if_lead_package_status',
  IfPackageTag = 'if_package_tag',
}

export const DataFieldEmailTypes: Array<DataFieldType> = [DataFieldType.Email]

export enum LeadStatusColor {
  Red = 'red',
  Pink = 'pink',
  Purple = 'purple',
  Blue = 'blue',
  DarkBlue = 'dark_blue',
  Green = 'green',
  LightGreen = 'light_green',
  DarkGreen = 'dark_green',
  Gray = 'gray',
  Silver = 'silver',
  Gold = 'gold',
  Orange = 'orange',
  Brown = 'brown',
  DarkBrown = 'dark_brown',
  Magenta = 'magenta',
  Primary = 'primary',
  Facebook = 'facebook',
  Zoom = 'zoom',
}

export const LeadStatusColorOptions = [
  {
    label: 'Red',
    value: LeadStatusColor.Red,
  },
  {
    label: 'Pink',
    value: LeadStatusColor.Pink,
  },
  {
    label: 'Purple',
    value: LeadStatusColor.Purple,
  },
  {
    label: 'Blue',
    value: LeadStatusColor.Blue,
  },
  {
    label: 'Dark Blue',
    value: LeadStatusColor.DarkBlue,
  },
  {
    label: 'Green',
    value: LeadStatusColor.Green,
  },
  {
    label: 'Light Green',
    value: LeadStatusColor.LightGreen,
  },
  {
    label: 'Dark Green',
    value: LeadStatusColor.DarkGreen,
  },
  {
    label: 'Gray',
    value: LeadStatusColor.Gray,
  },
  {
    label: 'Silver',
    value: LeadStatusColor.Silver,
  },
  {
    label: 'Gold',
    value: LeadStatusColor.Gold,
  },
  {
    label: 'Orange',
    value: LeadStatusColor.Orange,
  },
  {
    label: 'Magenta',
    value: LeadStatusColor.Magenta,
  },
  {
    label: 'Brown',
    value: LeadStatusColor.Brown,
  },
  {
    label: 'Dark Brown',
    value: LeadStatusColor.DarkBrown,
  },
]

export const LeadStatusColorToClassMap: { [x: string]: string } = {
  [LeadStatusColor.Red]: 'status-red',
  [LeadStatusColor.Pink]: 'status-pink',
  [LeadStatusColor.Purple]: 'status-purple',
  [LeadStatusColor.Blue]: 'status-blue',
  [LeadStatusColor.DarkBlue]: 'status-dark-blue',
  [LeadStatusColor.Green]: 'status-green',
  [LeadStatusColor.LightGreen]: 'status-light-green',
  [LeadStatusColor.DarkGreen]: 'status-dark-green',
  [LeadStatusColor.Gray]: 'status-gray',
  [LeadStatusColor.Silver]: 'status-silver',
  [LeadStatusColor.Gold]: 'status-gold',
  [LeadStatusColor.Orange]: 'status-orange',
  [LeadStatusColor.Brown]: 'status-brown',
  [LeadStatusColor.DarkBrown]: 'status-dark-brown',
  [LeadStatusColor.Magenta]: 'status-magenta',
  [LeadStatusColor.Primary]: 'primary',
  [LeadStatusColor.Facebook]: 'facebook',
  [LeadStatusColor.Zoom]: 'zoom',
}

export const DataFieldTypeToInputMap: { [x: string]: Component } = {
  [DataFieldType.Select]: SelectDataField,
  [DataFieldType.Status]: SelectDataField,
  [DataFieldType.Location]: SelectDataField,
  [DataFieldType.Source]: SelectDataField,
  [DataFieldType.Gender]: SelectDataField,
  [DataFieldType.Language]: SelectDataField,
  [DataFieldType.Relationship]: SelectDataField,
  [DataFieldType.CheckBox]: CheckboxDataField,
  [DataFieldType.Checkboxes]: CheckboxesDataField,
  [DataFieldType.Text]: TextDataField,
  [DataFieldType.Url]: TextDataField,
  [DataFieldType.Date]: DateDataField,
  [DataFieldType.DateTime]: DateTimeDataField,
  [DataFieldType.Phone]: PhoneDataField,
  [DataFieldType.Address]: AddressDataField,
  [DataFieldType.CompanyType]: SelectDataField,
  [DataFieldType.Email]: TextDataField,
  [DataFieldType.Number]: TextDataField,
  [DataFieldType.ChildClassroom]: SelectDataField,
  [DataFieldType.Schedule]: ScheduleDataField,
  [DataFieldType.BusinessUser]: SelectDataField,
  [DataFieldType.Textarea]: TextareaDataField,
  [DataFieldType.LeadLink]: TextDataField,
}

// TODO match PascalCase
export enum CriteriaGroupTrigger {
  LEAD = 'LEAD_UPDATED',
  LEAD_CHILD = 'CHILD_UPDATED',
}

export const CriteriaGroupTriggerToTriggerComponent: {
  [x: string]: Component
} = {
  [CriteriaGroupTrigger.LEAD]: LeadDataFieldTrigger,
  [CriteriaGroupTrigger.LEAD_CHILD]: LeadChildDataFieldTrigger,
}

// TODO match PascalCase
export enum CriteriaGroupOperators {
  AND = '1',
  OR = '2',
}

export enum CriteriaOperators {
  Greater = 'greater',
  Lesser = 'lesser',
  GreaterOrEqual = 'greater_or_equal',
  LesserOrEqual = 'lesser_or_equal',
  Equal = 'equal',
  In = 'in',
  NotIn = 'not_in',
  NotEmpty = 'not_empty',
  Contains = 'contains',
  IsNotEmpty = 'is_not_empty',
  Between = 'between', // only Age currently has it, if there is anything else in future, then modify 'useCriteriaValidators'
  IsEmpty = 'is_empty',
  Deleted = 'deleted',
}

// used in workflows for displaying correct operator (2nd option, after selecting data field for filter)
export const DataFieldTypeToOperatorMap: {
  [x: string]: { [y: string]: Component }
} = {
  [DataFieldType.Select]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Status]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Location]: {
    [CriteriaOperators.In]: InLocationOperator,
    [CriteriaOperators.NotIn]: InLocationOperator,
  },
  [DataFieldType.Source]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Gender]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Language]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Relationship]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.CheckBox]: {
    [CriteriaOperators.Equal]: EqualCheckboxOperator,
  },
  [DataFieldType.Checkboxes]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Text]: {
    [CriteriaOperators.Contains]: EqualTextOperator,
    [CriteriaOperators.Equal]: EqualTextOperator,
  },
  [DataFieldType.Url]: {
    [CriteriaOperators.Equal]: EqualTextOperator,
    [CriteriaOperators.Contains]: EqualTextOperator,
  },
  [DataFieldType.Date]: {
    [CriteriaOperators.Equal]: DateDataFieldWithoutLabel,
    [CriteriaOperators.Greater]: DateDataFieldWithoutLabel,
    [CriteriaOperators.GreaterOrEqual]: DateDataFieldWithoutLabel,
    [CriteriaOperators.Lesser]: DateDataFieldWithoutLabel,
    [CriteriaOperators.LesserOrEqual]: DateDataFieldWithoutLabel,
    [CriteriaOperators.Between]: DateRangeDataFieldWithoutLabel,
  },
  [DataFieldType.Phone]: {
    [CriteriaOperators.Contains]: EqualTextOperator,
    [CriteriaOperators.Equal]: EqualTextOperator,
  },
  [DataFieldType.PhoneNumber]: {
    [CriteriaOperators.In]: InPhoneNumbersOperator,
    [CriteriaOperators.NotIn]: InPhoneNumbersOperator,
  },
  [DataFieldType.Address]: {
    [CriteriaOperators.Contains]: EqualTextOperator,
    [CriteriaOperators.Equal]: EqualTextOperator, // TODO - using simple string search for now until address data fielc compomenet gets fixed
    // [CriteriaOperators.Equal]: SearchAddressField // HINT: must be used with wrapper SearchAddressField. Read inside component for more info
  },
  [DataFieldType.Number]: {
    [CriteriaOperators.Greater]: EqualNumberOperator,
    [CriteriaOperators.GreaterOrEqual]: EqualNumberOperator,
    [CriteriaOperators.Lesser]: EqualNumberOperator,
    [CriteriaOperators.LesserOrEqual]: EqualNumberOperator,
    [CriteriaOperators.Equal]: EqualNumberOperator,
  },
  [DataFieldType.TaskStatus]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.ScheduleStatus]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.ScheduleField]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.EmailConsent]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.PhoneConsent]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Age]: {
    [CriteriaOperators.Between]: BetweenAgeOperator,
  },
  [DataFieldType.ChildClassroom]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.DeletedReason]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.BusinessUser]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.Schedule]: {
    [CriteriaOperators.Greater]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.GreaterOrEqual]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.Lesser]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.LesserOrEqual]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.Equal]: DateTimeDataFieldWithoutLabel,
  },
  [DataFieldType.DateTime]: {
    [CriteriaOperators.Greater]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.GreaterOrEqual]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.Lesser]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.LesserOrEqual]: DateTimeDataFieldWithoutLabel,
    [CriteriaOperators.Equal]: DateTimeDataFieldWithoutLabel,
  },
  [DataFieldType.Email]: {
    [CriteriaOperators.Equal]: EqualTextOperator,
    [CriteriaOperators.Contains]: EqualTextOperator,
  },
  [DataFieldType.Textarea]: {
    [CriteriaOperators.Equal]: TextareaDataField,
    [CriteriaOperators.Contains]: TextareaDataField,
  },
  [DataFieldType.Utm]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.IfLeadPackageStatus]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
  [DataFieldType.IfPackageTag]: {
    [CriteriaOperators.In]: InSelectOperator,
    [CriteriaOperators.NotIn]: InSelectOperator,
  },
}

export const DataFieldsHavingCustomValues: Array<DataFieldType> = [
  DataFieldType.Select,
  DataFieldType.Status,
  DataFieldType.Location,
  DataFieldType.Source,
  DataFieldType.Gender,
  DataFieldType.Language,
  DataFieldType.Relationship,
  DataFieldType.Utm,
  DataFieldType.DeletedReason,
  DataFieldType.LeadLink,
  DataFieldType.Checkboxes,
]

// HINT: when adding new action, also add an 'icon' in WorkflowActionIconMap
export enum ActionType {
  EmailTemplate = 'SEND_EMAIL',
  SmsTemplate = 'SEND_SMS',
  DataField = 'CHANGE_DATA_FIELD_VALUE',
  ChildDataField = 'CHANGE_CHILD_DATA_FIELD_VALUE',
  TaskTemplate = 'CREATE_TASK',
  Schedule = 'CHOOSE_SCHEDULE',
  UpdateScheduleStatus = 'UPDATE_SCHEDULE_STATUS',
  EmailToCustomRecipients = 'SEND_EMAIL_TO_CUSTOM_RECIPIENTS',
  SMSToCustomRecipients = 'SEND_SMS_TO_CUSTOM_RECIPIENTS',
  PushToProcareModerationEngine = 'PUSH_DATA_TO_PROCARE_MODERATION_ENGINE',
  PushToProcareOnline = 'PUSH_DATA_TO_PROCARE_ONLINE',
  PushToKangarootime = 'PUSH_DATA_TO_KANGAROOTIME',
  PushToFamly = 'PUSH_DATA_TO_FAMLY',
  SendFacebookMessage = 'SEND_FACEBOOK_MESSAGE',
  ChangeActiveTaskStatus = 'CHANGE_ACTIVE_TASK_STATUS',
  ResolveLeadStatusByChildrenStatus = 'RESOLVE_LEAD_STATUS_BY_CHILDREN_STATUS',
  DeleteAllScheduledWorkflowActions = 'DELETE_ALL_SCHEDULED_WORKFLOW_ACTIONS',
  CreateLeadPackage = 'CREATE_LEAD_PACKAGE'
  // Status = 4,
}

// TODO separate workflows specific constants and move to "constants/workflows/index.ts"
// including "ActionTypeToComponent"
export const ActionTypeToComponent: { [x: string]: Component } = {
  [ActionType.EmailTemplate]: EmailTemplateAction,
  [ActionType.SmsTemplate]: SmsTemplateAction,
  [ActionType.DataField]: DataFieldAction,
  [ActionType.ChildDataField]: ChildDataFieldAction,
  [ActionType.TaskTemplate]: TaskTemplateAction,
  [ActionType.Schedule]: ScheduleAction,
  [ActionType.UpdateScheduleStatus]: UpdateScheduleStatusAction,
  [ActionType.EmailToCustomRecipients]: EmailToCustomRecipientTemplateAction,
  [ActionType.SMSToCustomRecipients]: SmsToCustomRecipientTemplateAction,
  [ActionType.PushToProcareModerationEngine]: PushToProcareModerationEngineAction,
  [ActionType.PushToProcareOnline]: PushToProcareOnlineAction,
  [ActionType.PushToKangarootime]: PushToKangarootimeAction,
  [ActionType.PushToFamly]: PushToFamlyAction,
  [ActionType.SendFacebookMessage]: FacebookTemplateAction,
  [ActionType.ChangeActiveTaskStatus]: ChangeActiveTaskStatusAction,
  [ActionType.ResolveLeadStatusByChildrenStatus]: ResolveLeadStatusAction,
  [ActionType.DeleteAllScheduledWorkflowActions]: DeleteAllScheduledWorkflowAction,
  [ActionType.CreateLeadPackage]: CreateLeadPackageAction,
}

export enum MenuRouteCategory {
  Business = 'business',
  Lms = 'lms',
  Ems = 'ems',
  Branding = 'branding',
  Access = 'access',
}

export enum GoogleAddressType {
  Country = 'country',
  District = 'administrative_area_level_1',
  City = 'locality',
  PostalCode = 'postal_code',
  Apartment = 'room',
  Street = 'route',
  StreetNumber = 'street_number',
}

export type LatLng = {
  lat: number
  lng: number
}

export type GoogleAddressItem = {
  long_name: string | null
  short_name: string | null
}

export type GoogleAddress = {
  country_id: string
  country?: CommonCountryResponseDto
  address: string
  address_two?: string
  city: string
  state: string
  zip: string
  lat?: string
  lng?: string
  full_address: string
}

export type GoogleAutosuggestAddress = {
  address: string
  city: string
  state: string
  zip: string
  lat: number
  lng: number
  full_address: string
}

export type BadgeSize = 'xs' | 'sm' | 'md' | 'lg'

export enum ConversationSettingsScheduleType {
  During = 1,
  Outside = 2,
  Any = 3,
}

export enum GeneralSettingsKey {
  Locale = 'locale',
  Timezone = 'timezone',
}

export enum LeadActions {
  Email = 'email',
  SMS = 'sms',
  Call = 'call',
  Facebook = 'facebook',
  Messenger = 'messenger',
  ChangeStatus = 'change-status',
  Comment = 'comment',
  CreateTask = 'create-task',
  ChooseSchedule = 'choose-schedule',
}

export enum SubscriptionBillingPeriodEnum {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export enum RegistrationStepEnum {
  UserStep = 'user_step',
  BusinessStep = 'business_step',
  LocationStep = 'location_step',
  CompanyStep = 'company_step',
  TermStep = 'term_step',
  PaymentStep = 'payment_step',
  RegistrationFinished = 'registration_finished',
}

export enum BusinessTypeEnum {
  Demo = 'demo',
  Test = 'test',
  New = 'new',
  Pending = 'pending',
  Subscribed = 'subscribed',
  Inactive = 'inactive',
  Install = 'install',
}

export enum Direction {
  Outgoing = 1,
  Incoming = 2,
}

export const DirectionLabelMap: { [x: string]: string } = {
  [Direction.Outgoing]: 'Outgoing',
  [Direction.Incoming]: 'Incoming',
}

export const IntelliFormsDocumentStatusColorMap: {
  [x: string]: LeadStatusColor
} = {
  [IntelliFormLeadDocumentStatusEnum.NEW]: LeadStatusColor.Gray,
  [IntelliFormLeadDocumentStatusEnum.INCOMPLETE]: LeadStatusColor.Red,
  [IntelliFormLeadDocumentStatusEnum.COMPLETED]: LeadStatusColor.Primary,
  [IntelliFormLeadDocumentStatusEnum.SUBMITTED]: LeadStatusColor.Primary,
}

export const IntelliFormsStatusColorMap: { [x: string]: string } = {
  [IntelliFormLeadPackageStatusEnum.NEW]: 'bg-warning text-warning',
  [IntelliFormLeadPackageStatusEnum.STARTED]: 'bg-warning text-warning',
  [IntelliFormLeadPackageStatusEnum.FINISHED]: 'bg-success text-success',
  [IntelliFormLeadPackageStatusEnum.PAID]: 'bg-success text-success',
  [IntelliFormLeadPackageStatusEnum.SUBMITTED]: 'bg-success text-success',
}

export interface DraggableChangeEvent<T> {
  added?: {
    newIndex: number
    element: T
  }
  removed?: {
    oldIndex: number
    element: T
  }
  moved?: DraggableMoveEvent<T>
}

export interface DraggableMoveEvent<T> {
  element: T
  newIndex: number
  oldIndex: number
}

export enum BootstrapColorEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Muted = 'muted',
  White = 'white',
}

export enum Locale {
  US = 'en-US',
  GB = 'en-GB',
  AU = 'en-AU',
  CA = 'en-CA',
  NZ = 'en-NZ',
}

export enum SettingLocale {
  US = 'en_US',
  GB = 'en_GB',
  AU = 'en_AU',
  CA = 'en_CA',
  NZ = 'en_NZ',
}

export const LocaleToTextMap: { [x: string]: string } = {
  [SettingLocale.AU]: 'English (Australia)',
  [SettingLocale.CA]: 'English (Canada)',
  [SettingLocale.NZ]: 'English (New Zealand)',
  [SettingLocale.GB]: 'English (United Kingdom)',
  [SettingLocale.US]: 'English (United States)',
}

export enum CountryCodeIso2 {
  US = 'US',
  GB = 'GB',
  AU = 'AU',
  CA = 'CA',
  NZ = 'NZ',
}

export enum CountryCodeIso3 {
  US = 'USA',
  GB = 'GBR',
  AU = 'AUS',
  CA = 'CAN',
  NZ = 'NZL',
}

export const CountryCodeIso2ToIntDialingCode: { [x: string]: string } = {
  [CountryCodeIso2.US]: '1',
  [CountryCodeIso2.GB]: '44',
  [CountryCodeIso2.AU]: '61',
  [CountryCodeIso2.CA]: '1',
  [CountryCodeIso2.NZ]: '64',
}

export const ImagePlaceholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAABaCAYAAADkUTU1AAAAcUlEQVR42u3PQQEAAAQEMPq/JD4RBLA1WNck9UgLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLCwsLC98WVHbmofAERcoAAAAASUVORK5CYII='

export const ImageFileUploadSize = 5

export enum FileMimeTypes {
  ApplicationAll = 'application/*',
  ApplicationPdf = 'application/pdf',
  ApplicationJson = 'application/json',
  ApplicationZip = 'application/zip',
  ImageAll = 'image/*',
  ImagePng = 'image/png',
  ImageJpg = 'image/jpeg',
  ImageSvg = 'image/svg+xml',
  ImageWebp = 'image/webp',
  VideoAll = 'video/*',
  VideoMpeg = 'video/mpeg',
  AudioAll = 'audio/*',
  AudioMp3 = 'audio/mpeg',
}

export enum BuildVersion {
  Local = 'LOCAL',
  Main = 'main',
  Production = 'production',
}

export const BusinessTypeOptions = [
  {
    label: 'Sole Proprietorship',
    value: BusinessTypeValues.SoleProprietorship,
  },
  {
    label: 'Corporation',
    value: BusinessTypeValues.Corporation,
  },
  {
    label: 'LLC',
    value: BusinessTypeValues.LLC,
  },
  {
    label: 'Partnership',
    value: BusinessTypeValues.Partnership,
  },
  {
    label: 'Co-Operative',
    value: BusinessTypeValues.CoOperative,
  },
  {
    label: 'Non Profit',
    value: BusinessTypeValues.NonProfit,
  },
]

export const MerchantBusinessTypeOptions = [
  {
    label: 'Sole Proprietorship',
    value: MerchantBusinessTypeEnum.Individual,
  },
  {
    label: 'Corporation',
    value: MerchantBusinessTypeEnum.Corporation,
  },
  {
    label: 'LLC',
    value: MerchantBusinessTypeEnum.LLC,
  },
  {
    label: 'Partnership',
    value: MerchantBusinessTypeEnum.Partnership,
  },
  {
    label: 'Association Estate Trust',
    value: MerchantBusinessTypeEnum.AssociationEstateTrust,
  },
  {
    label: 'Tax Exempt Organization',
    value: MerchantBusinessTypeEnum.TaxExcemptOrganization,
  },
  {
    label: 'International Organization',
    value: MerchantBusinessTypeEnum.InternationalOrganization,
  },
  {
    label: 'Government Agency',
    value: MerchantBusinessTypeEnum.GovernmentAgency,
  },
]

export const CompanyBusinessTypeToTypeMap: { [x: string]: number } = {
  [BusinessTypeValues.SoleProprietorship]: MerchantBusinessTypeEnum.Individual,
  [BusinessTypeValues.Partnership]: MerchantBusinessTypeEnum.Partnership,
  [BusinessTypeValues.Corporation]: MerchantBusinessTypeEnum.Corporation,
  [BusinessTypeValues.LLC]: MerchantBusinessTypeEnum.LLC,
}

export enum BusinessRegistrationStage {
  Unregistered = 'Unregistered business',
}

export enum SchedulerTimeSlotFrequency {
  Default = 15,
}

export enum ListViewTypeEnum {
  Calendar = 'calendar',
  List = 'list',
}

export enum CalendarPopoverTypes {
  Task = 'task',
  Scheduled = 'scheduled',
  ActionsToTake = 'actions',
}

export const UtmSourcesLabelMap = {
  [DataFieldName.UtmSource as string]: 'UTM Source',
  [DataFieldName.UtmMedium as string]: 'UTM Medium',
  [DataFieldName.UtmCampaign as string]: 'UTM Campaign',
  [DataFieldName.UtmTerm as string]: 'UTM Term',
  [DataFieldName.UtmContent as string]: 'UTM Content',
}

export const DummyBackOfficeSlug = 'bo'
export const NoLocationTitle = '-No Location-'
export const MissingNamePlaceholder = '-No Name-'

export enum RoleTypeNames {
  Admin = 'admin',
  AdminOwner = 'admin_owner',
  Owner = 'owner',
}
