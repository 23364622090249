export enum AdminAbilityEnum {
  BUSINESS = 'business',

  BUSINESS_CREATE = 'business:create',

  BUSINESS_DELETE = 'business:delete',

  BUSINESS_UPDATE = 'business:update',

  BUSINESS_VIEW = 'business:view',

  BUSINESS_SUBSCRIPTIONS_ASSIGN = 'business_subscriptions:assign',

  USER = 'user',

  USER_BECOME = 'user:become',

  ADMIN = 'admin',

  ADMIN_ADMINS = 'admin_admins',

  ADMIN_ADMINS_UPDATE = 'admin_admins:update',

  ADMIN_ADMINS_CREATE = 'admin_admins:create',

  ADMIN_ADMINS_DELETE = 'admin_admins:delete',

  ADMIN_ROLES = 'admin_roles',

  ADMIN_ROLES_CREATE = 'admin_roles:create',

  ADMIN_ROLES_DELETE = 'admin_roles:delete',

  ADMIN_ROLES_UPDATE = 'admin_roles:update',

  ADMIN_ROLES_MANAGE = 'admin_roles:manage',

  SUBSCRIPTION = 'subscription',

  SUBSCRIPTION_SUBSCRIPTIONS = 'subscription_subscriptions',

  SUBSCRIPTION_SUBSCRIPTIONS_CREATE = 'subscription_subscriptions:create',

  SUBSCRIPTION_SUBSCRIPTIONS_UPDATE = 'subscription_subscriptions:update',

  SUBSCRIPTION_SUBSCRIPTIONS_DELETE = 'subscription_subscriptions:delete',

  SUBSCRIPTION_SUBSCRIPTIONS_VIEW = 'subscription_subscriptions:view',

  SUBSCRIPTION_SERVICES = 'subscription_services',

  SUBSCRIPTION_SERVICES_CREATE = 'subscription_services:create',

  SUBSCRIPTION_SERVICES_UPDATE = 'subscription_services:update',

  SUBSCRIPTION_SERVICES_DELETE = 'subscription_services:delete',

  SUBSCRIPTION_SERVICES_VIEW = 'subscription_services:view',

  FEATURE = 'feature',

  FEATURE_UPDATE = 'feature:update',

  INSTALLATION_PACKAGE = 'installation_package',

  INSTALLATION_PACKAGE_CREATE = 'installation_package:create',

  INSTALLATION_PACKAGE_DELETE = 'installation_package:delete',

  INSTALLATION_PACKAGE_UPDATE = 'installation_package:update',

  INSTALLATION_PACKAGE_VIEW = 'installation_package:view',

  CONFIGURATION = 'configuration',

  CONFIGURATION_CREATE = 'configuration:create',

  CONFIGURATION_DELETE = 'configuration:delete',

  CONFIGURATION_UPDATE = 'configuration:update',

  CONFIGURATION_VIEW = 'configuration:view',

  EMAIL = 'email',

  EMAIL_VIEW = 'email:view',

  EMAIL_MANAGE = 'email:manage',

  LOG = 'log',

  LOG_VIEW = 'log:view',

  INTEGRATION = 'integration',

  INTEGRATION_TWILIO = 'integration_twilio',

  INTEGRATION_TWILIO_MANAGE = 'integration_twilio:manage',

  INTEGRATION_TWILIO_VIEW = 'integration_twilio:view',

  INTEGRATION_MAILGUN = 'integration_mailgun',

  INTEGRATION_MAILGUN_MANAGE = 'integration_mailgun:manage',

  INTEGRATION_MAILGUN_VIEW = 'integration_mailgun:view',

  INTEGRATION_INTERCOM = 'integration_intercom',

  INTEGRATION_INTERCOM_MANAGE = 'integration_intercom:manage',

  INTEGRATION_INTERCOM_VIEW = 'integration_intercom:view',

  INTEGRATION_GOOGLE = 'integration_google',

  INTEGRATION_GOOGLE_MANAGE = 'integration_google:manage',

  INTEGRATION_GOOGLE_VIEW = 'integration_google:view',

  INTEGRATION_BEEFREE = 'integration_beefree',

  INTEGRATION_BEEFREE_MANAGE = 'integration_beefree:manage',

  INTEGRATION_BEEFREE_VIEW = 'integration_beefree:view',

  INTEGRATION_FACEBOOK = 'integration_facebook',

  INTEGRATION_FACEBOOK_MANAGE = 'integration_facebook:manage',

  INTEGRATION_FACEBOOK_VIEW = 'integration_facebook:view',

  INTEGRATION_MICROSOFT = 'integration_microsoft',

  INTEGRATION_MICROSOFT_MANAGE = 'integration_microsoft:manage',

  INTEGRATION_MICROSOFT_VIEW = 'integration_microsoft:view',

  INTEGRATION_ZOOM = 'integration_zoom',

  INTEGRATION_ZOOM_MANAGE = 'integration_zoom:manage',

  INTEGRATION_ZOOM_VIEW = 'integration_zoom:view',

  INTEGRATION_LOOKER_STUDIO = 'integration_looker_studio',

  INTEGRATION_LOOKER_STUDIO_MANAGE = 'integration_looker_studio:manage',

  INTEGRATION_LOOKER_STUDIO_VIEW = 'integration_looker_studio:view',

  INTEGRATION_PROCARE = 'integration_procare',

  INTEGRATION_PROCARE_MANAGE = 'integration_procare:manage',

  INTEGRATION_PROCARE_VIEW = 'integration_procare:view',

  INTEGRATION_KANGAROOTIME = 'integration_kangarootime',

  INTEGRATION_KANGAROOTIME_MANAGE = 'integration_kangarootime:manage',

  INTEGRATION_KANGAROOTIME_VIEW = 'integration_kangarootime:view',

  INTEGRATION_FINIX = 'integration_finix',

  INTEGRATION_FINIX_MANAGE = 'integration_finix:manage',

  INTEGRATION_FINIX_VIEW = 'integration_finix:view',

  INTEGRATION_CHECKR = 'integration_checkr',

  INTEGRATION_CHECKR_MANAGE = 'integration_checkr:manage',

  INTEGRATION_CHECKR_VIEW = 'integration_checkr:view',

  INTEGRATION_INDEED = 'integration_indeed',

  INTEGRATION_INDEED_MANAGE = 'integration_indeed:manage',

  INTEGRATION_INDEED_VIEW = 'integration_indeed:view',

  INTEGRATION_CARE = 'integration_care',

  INTEGRATION_CARE_MANAGE = 'integration_care:manage',

  INTEGRATION_CARE_VIEW = 'integration_care:view',

  INTEGRATION_PSPDFKIT = 'integration_pspdfkit',

  INTEGRATION_PSPDFKIT_MANAGE = 'integration_pspdfkit:manage',

  INTEGRATION_PSPDFKIT_VIEW = 'integration_pspdfkit:view'
}
