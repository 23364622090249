import { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'
import { FeatureEnum } from '@/enums/FeatureEnum'

export default <RouteRecordRaw[]>[
  {
    path: RouteList.LEARNING.ACADEMY_REDIRECT.PATH,
    name: RouteList.LEARNING.ACADEMY_REDIRECT.NAME,
    redirect: () => {
      window.open(
        import.meta.env.VITE_LEARNING_ACADEMY_URL ?? 'https://learn.intellikidsystems.com',
        '_blank',
      )
      return ''
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.LEARNING, FeatureEnum.LEARNING_ACADEMY],
    },
  },
  {
    path: RouteList.LEARNING.HELP_CENTER.PATH,
    name: RouteList.LEARNING.HELP_CENTER.NAME,
    redirect: () => {
      window.open(import.meta.env.VITE_EXTERNAL_IKS_HELP_CENTER, '_blank')
      return ''
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.LEARNING, FeatureEnum.LEARNING_HELP_CENTER],
    },
  },
]
