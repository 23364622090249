import client from '@/api/client'
import { AxiosResponse } from 'axios'
import { ResponseOne } from '@/api/PaginatedResponse'

export interface NationalHolidayDataDto {
  value: string // e.g. Australia, Canada, USA
  text: string
}

export type GetNationalHolidaysResponseDto = AxiosResponse<
  ResponseOne<NationalHolidayDataDto[]>
>

export function getNationalHolidays(): Promise<GetNationalHolidaysResponseDto> {
  return client.get(`/setting/national-holidays`)
}

// /api/setting/national-holidays
// /api/setting/national-holiday
