import { RouteRecordRaw } from 'vue-router'
import { RouteList } from '@/routes/RouteList'

const UnsubscribeView = () => import('@/views/Public/Unsubscribe/UnsubscribeView.vue')

const PublicSchedulerView = () => import('@/views/Public/Scheduler/PublicSchedulerView.vue')

export default <RouteRecordRaw[]>[
  {
    path: RouteList.PUBLIC.UNSUBSCRIBE.PATH,
    name: RouteList.PUBLIC.UNSUBSCRIBE.NAME,
    components: {
      default: UnsubscribeView,
    },
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: RouteList.PUBLIC.SCHEDULER.PATH,
    name: RouteList.PUBLIC.SCHEDULER.NAME,
    component: PublicSchedulerView,
  },
]
