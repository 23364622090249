import client from '@/api/client'
import { AxiosResponse } from 'axios'

interface OnRampWidgetConfig {
  organization: string
  user: string
  scheme: string
}

export function getOnRampWidget(config: OnRampWidgetConfig): Promise<AxiosResponse<string>> {
  return client.post('/integration/onramp/embed/get-widget', config)
}