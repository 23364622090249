export default {
  MISC: {
    PLACEHOLDER: {
      PATH: '/placeholder',
      NAME: 'placeholder',
    },
    NOT_FOUND: {
      PATH: '/:pathMatch(.*)*',
      NAME: 'dashboard.not-found',
    },
    UNAUTHORIZED: {
      PATH: '/:pathMatch(.*)*',
      NAME: 'dashboard.unauthorized',
    },
    ERROR: {
      PATH: '/error/:errorCode',
      NAME: 'dashboard.error',
    },
  },
}
