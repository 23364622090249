export enum MerchantProvisionStatusEnum {
  Approved = 1,
  Provisioning = 2,
  Rejected = 3
}

export enum MerchantApplicationStatusEnum {
  Processing = 1,
  Success = 2,
  Error = 3
}

export enum MerchantBusinessTypeEnum {
  Individual = 1,
  Corporation = 2,
  LLC = 3,
  Partnership = 4,
  AssociationEstateTrust = 5,
  TaxExcemptOrganization = 6,
  InternationalOrganization = 7,
  GovernmentAgency = 8,
}

export enum MerchantBusinessOwnershipTypeEnum {
  Public = 1,
  Private = 2,
}

export enum MerchantBusinessRefundPolicyEnum {
  NoRefunds = 1,
  MerchandiseExchangeOnly = 2,
  Within30Days = 3,
  Other = 4,
}

export enum BankAccountTypeEnum {
  Checking = 1,
  Savings = 2,
}

export enum BankAccountTypeNameEnum {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export enum PaymentMethodTypeEnum {
  Card = 'PAYMENT_CARD',
  BankAccount = 'BANK_ACCOUNT',
  ApplePay = 'APPLE_PAY',
}

export enum PaymentReservationStateTypeEnum {
  Succeeded = 1,
  Failed = 2,
}

export const enum PaymentReservationCaptureStateTypeEnum {
  Succeeded = 1,
  Pending = 2,
  NotCaptured = 3,
}

export const enum FinixBankAccountErrorTypeEnum {
  AccountTypeError = 'Invalid Field: account_type;  Should be one of CHECKING, SAVINGS, CORPORATE, CORP_SAVINGS. Error near line: -1, column: -1',
  AccountTypeErrorMessage = 'Account type should be one of CHECKING, SAVINGS, CORPORATE, CORP_SAVINGS',
}

export const enum FinixPaymentFieldTypeEnum {
  RoutingNumber = 'routing_number',
}

export enum TransactionStatusTypeEnum {
  Succeeded = 1,
  Failed = 2,
  Pending = 3,
  Canceled = 4,
  Unknown = 5,
}

export const enum FinixErrorFieldMessageEnum {
  SecurityCodeLength = 'must be numeric and 3-4 digits',
  ExpirationDateNotNull = 'expiration_month must not be null.',
  CardNumberInvalid = 'Invalid card number',
  RoutingNumberLength = 'Routing Number must be exactly 9 numeric characters long',
  PaymentInstrumentCannotBeUsed = 'The provided payment instrument can not be used. The bank account failed validation. The account holder should use another bank account.',
}

export const enum FinixSettlementStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  FAILED = 'FAILED'
}

export const enum FinixSettlementTypeEnum {
  MERCHANT_REVENUE = 'MERCHANT_REVENUE',
  PLATFORM_FEE = 'PLATFORM_FEE',
  PARTNER_FEE = 'PARTNER_FEE',
  NOOP = 'NOOP',
  MERCHANT = 'MERCHANT',
  APPLICATION = 'APPLICATION',
  PLATFORM = 'PLATFORM',
}

export enum MerchantComplianceFormStateEnum {
  Incomplete = 1, // Forms that are not yet signed, and not yet past their due date
  Overdue = 2, // Forms that are not yet signed, and past their due date
  Completed = 3, // Forms that are signed and not yet past their valid until date
  Expired = 4, // Forms that are signed and past their valid until date
  Invalid = 5 // Forms that have been archived altogether
}
