import { ComposerOptions, createI18n, I18n } from 'vue-i18n'
import client from '@/api/client'
import store from '@/store'
import { LocaleSettings } from '@/store/Dashboard/dashboard.repository'
import { Locale } from '@/shared'

class AppI18n {
  private static _instance?: I18n<
    ComposerOptions['messages'],
    ComposerOptions['datetimeFormats'],
    ComposerOptions['numberFormats'],
    Locale,
    false
  >

  public static get instance() {
    return AppI18n.setup()
  }

  private static get persistedLocale(): Locale | null {
    return store.getters['DashboardModule/locale']
  }

  private static get fallbackLocale() {
    return Locale.US
  }

  private static get locale() {
    return AppI18n.persistedLocale ?? AppI18n.fallbackLocale
  }

  static async isReady(): Promise<void> {
    if (undefined === AppI18n._instance) {
      throw new Error('setup() method should be called first.')
    }
    const [language, country] = AppI18n.locale.split('-')
    await store.dispatch('DashboardModule/setLocale', <LocaleSettings>{
      language,
      country,
    })
  }

  private static setup() {
    if (AppI18n._instance) {
      return AppI18n._instance
    }

    AppI18n._instance = createI18n<false>({
      legacy: false,
      globalInjection: false,
      locale: AppI18n.locale,
      fallbackLocale: AppI18n.fallbackLocale,
      datetimeFormats: {},
      missingWarn: false,
      fallbackWarn: false,
      numberFormats: {
        USD: {
          currency: {
            style: 'currency',
            currency: 'USD',
          },
        },
        GBP: {
          currency: {
            style: 'currency',
            currency: 'GBP',
          },
        },
        AUD: {
          currency: {
            style: 'currency',
            currency: 'AUD',
          },
        },
        CAD: {
          currency: {
            style: 'currency',
            currency: 'CAD',
          },
        },
        NZD: {
          currency: {
            style: 'currency',
            currency: 'NZD',
          },
        },
        EUR: {
          currency: {
            style: 'currency',
            currency: 'EUR',
          },
        },
      },
    })

    return AppI18n._instance
  }

  private construct() {
    //
  }
}

export async function setLocale(locale: LocaleSettings) {
  const language = <Locale>`${locale.language}-${locale.country}`
  const configureLocale = () => {
    client.defaults.headers.common['Accept-Language'] = language
    document.querySelector('html')?.setAttribute('lang', language)
  }
  const doSet = () => {
    AppI18n.instance.global.locale.value = language
    configureLocale()
  }
  const loadLocaleMessages = async () => {
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */
      /* webpackMode: "lazy-once" */
      `./locales/${language}.json`
    )
    AppI18n.instance.global.setLocaleMessage(language, messages.default)
  }

  try {
    const messages = AppI18n.instance.global.getLocaleMessage(language)
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        doSet()
        return
      }
    }
    await loadLocaleMessages()
    doSet()
  } catch (e) {}
}

export default AppI18n
