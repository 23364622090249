import { Actions, Context, createComposable, Getters, Module, Mutations } from 'vuex-smart-module'
import { auth, AuthEvents } from '@/store/Auth'
import { Store } from 'vuex'
import { RuntimeModule } from '@/store/moduleType.js'

class TaskState {
  taskCount = 0
  isStoreLoaded = true
}

class TaskGetters extends Getters<TaskState> {
  get taskCount(): number {
    return this.state.taskCount
  }

  get isStoreLoaded(): boolean {
    return this.state.isStoreLoaded
  }
}

class TaskMutations extends Mutations<TaskState> {
  updateTaskCount(taskCount: number) {
    this.state.taskCount = taskCount
  }

  reset() {
    this.state.taskCount = 0
  }
}

class TaskActions extends Actions<TaskState, TaskGetters, TaskMutations, TaskActions> {
  auth?: Context<typeof auth>

  $init(store: Store<unknown>): void {
    this.auth = auth.context(store)
    store.subscribeAction(({ type }) => {
      if (`AuthModule/${AuthEvents.OnLogout}` === type) {
        this.dispatch('_reset')
      }
    })
  }

  updateTaskCount(taskCount: number) {
    this.mutations.updateTaskCount(taskCount)
  }

  _reset() {
    this.mutations.reset()
  }
}

export const task = new Module({
  state: TaskState,
  getters: TaskGetters,
  mutations: TaskMutations,
  actions: TaskActions,
}) as RuntimeModule<TaskState, TaskGetters, TaskMutations, TaskActions>

task.namespace = 'TaskModule/'
task.path = ['TaskModule']

export const useTask = createComposable(task)
