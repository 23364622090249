<template>
  <div class="d-flex justify-content-between">
    <div class="flag-select me-2">
      <a-select
        v-model:value="countryCode"
        size="large"
        class="w-100"
        option-filter-prop="label"
        show-search
        :get-popup-container="(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement"
        :placeholder="t('Select country')"
        :dropdown-match-select-width="200"
        :disabled="disabled"
      >
        <a-select-opt-group v-if="businessCountry">
          <template #label>
            <span>
              <strong>{{ t('Choose a Country') }}</strong>
            </span>
          </template>
          <a-select-option
            :label="businessCountry.name"
            :value="businessCountry.code"
            :title="businessCountry.name"
          >
            <SanitizedText
              :text="CountryFlagSvg[businessCountry.code]"
              as="i"
              role="img"
              class="flag d-inline-block me-2"
            />
            <span class="select-label">{{ businessCountry.name }}</span>
          </a-select-option>
        </a-select-opt-group>
        <a-select-opt-group>
          <template #label>
            <span class="group-border text-muted d-inline-block w-100"></span>
          </template>
          <a-select-option
            v-for="(country, index) in allCountries"
            :key="`select-option-${index}-${country.code}`"
            :label="country.name"
            :value="country.code"
            :title="country.name"
          >
            <SanitizedText
              :text="CountryFlagSvg[country.code]"
              as="i"
              role="img"
              class="flag d-inline-block me-2"
            />
            <span class="select-label">{{ country.name }}</span>
          </a-select-option>
        </a-select-opt-group>
      </a-select>
    </div>
    <div class="col">
      <input
        v-bind="$attrs"
        v-model="phoneValue"
        name="phone"
        class="form-control"
        :class="{
          'is-invalid':
            (phoneValue.length && !isValidPhoneNumber(phoneValue, countryCode)) || !!errorMessage
        }"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import type { E164Number } from 'libphonenumber-js/types'
import {
  isValidPhoneNumber,
  toInternational,
  toNationalPretty,
  getPhoneNumberCountry,
} from '@/helpers/phoneNumber.helper'
import { asyncComponentWrapper } from '@/composables/useReloadModal'
import CountryList from 'country-list-with-dial-code-and-flag'
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg'
import { useBusiness } from '@/store/Business'
import { CountryCodeIso2 } from '@/shared'

import { SelectOptGroup as ASelectOptGroup, SelectOption as ASelectOption } from 'ant-design-vue'
import SanitizedText from '@/components/Misc/SanitizedText.vue'
const ASelect = asyncComponentWrapper(() => import('ant-design-vue/es/select'))

const props = defineProps({
  modelValue: {
    type: [String, null] as PropType<string | null>,
    default: undefined,
  },
  errorMessage: {
    type: String,
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | E164Number): void
}>()

const { t } = useI18n()
const businessStore = useBusiness()

const countryLocale = businessStore.getters.country?.iso_alpha_2 ?? CountryCodeIso2.US

// Get the country from the phone number (only international phone numbers work)
// Or set the country as US by default
const countryCode = ref(
  props.modelValue ? getPhoneNumberCountry(props.modelValue) ?? countryLocale : countryLocale,
)

const allCountries = computed(() => CountryList.getAll())
const businessCountry = computed(() =>
  allCountries.value.find((country) => countryLocale === country.code),
)

const phoneValue = computed<string>({
  get() {
    return props.modelValue ? toNationalPretty(props.modelValue, countryCode.value) : ''
  },
  set(value: string) {
    emit('update:modelValue', toInternational(value, countryCode.value))
  },
})

watch(countryCode, () => {
  emit('update:modelValue', toInternational(phoneValue.value, countryCode.value))
})
</script>

<style lang="scss" scoped>
.flag-select {
  width: 76px;
}

.flag {
  height: 13px;
  width: 26px;
}

.ant-select-selection-item .select-label {
  display: none;
}

.group-border {
  border-top: 0.15rem dashed;
}
</style>
