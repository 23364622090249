import { RouteRecordRaw } from 'vue-router'
import { MenuCode } from '@/shared'
import { FeatureEnum } from '@/enums/FeatureEnum'
import { AbilityEnum } from '@/enums/AbilityEnum'
import { RouteList } from '@/routes/RouteList'

const NavBar = () => import('@/components/Layout/AuthenticatedNavBar.vue')
const SubSideBar = () => import('@/components/Layout/SubSideBar.vue')
const DashboardMain = () => import('@/components/Dashboard/DashboardMain.vue')

export default <RouteRecordRaw[]>[
  {
    path: RouteList.WEBSITE.INDEX.PATH,
    name: RouteList.WEBSITE.INDEX.NAME,
    components: {
      default: DashboardMain,
      SideBar: SubSideBar,
      NavBar,
    },
    props: {
      SideBar: {
        menuCode: MenuCode.Lms,
        title: 'Website',
      },
    },
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      feature: [FeatureEnum.WEBSITE],
      permission: [AbilityEnum.WEBSITE],
    },
  },
]
