import { RouteList } from '@/routes/RouteList'

export const LMS_ROUTES = [
  RouteList.LMS.LEADS.INDEX.NAME,
  RouteList.LMS.CENTER.INDEX.NAME,
  RouteList.LMS.STATISTICS.OVERVIEW.NAME,
  RouteList.LMS.STATISTICS.REPORTS.INDEX.NAME,
  RouteList.LMS.CONVERSATIONS.CALLS.NAME,
  RouteList.LMS.CONVERSATIONS.SMS.NAME,
  RouteList.LMS.CONVERSATIONS.EMAILS.NAME,
  RouteList.LMS.CONVERSATIONS.MESSENGER.NAME,
  RouteList.LMS.MARKETING.WORKFLOWS.INDEX.NAME,
  RouteList.LMS.MARKETING.TEMPLATES.INDEX.NAME,
  RouteList.LMS.MARKETING.CAMPAIGNS.INDEX.NAME,
  RouteList.LMS.INTELLIFORMS.PACKAGES.INDEX.NAME,
  RouteList.LMS.INTELLIFORMS.DOCUMENTS.INDEX.NAME,
]

export const EMS_ROUTES = [
  RouteList.EMS.LEADS.INDEX.NAME,
  RouteList.EMS.STATISTICS.OVERVIEW.NAME,
  RouteList.EMS.STATISTICS.REPORTS.INDEX.NAME,
  RouteList.EMS.CONVERSATIONS.CALLS.NAME,
  RouteList.EMS.CONVERSATIONS.SMS.NAME,
  RouteList.EMS.CONVERSATIONS.EMAILS.NAME,
  RouteList.EMS.CONVERSATIONS.MESSENGER.NAME,
  RouteList.EMS.MARKETING.WORKFLOWS.INDEX.NAME,
  RouteList.EMS.MARKETING.TEMPLATES.INDEX.NAME,
  RouteList.EMS.MARKETING.CAMPAIGNS.INDEX.NAME,
  RouteList.EMS.INTELLIFORMS.PACKAGES.INDEX.NAME,
  RouteList.EMS.INTELLIFORMS.DOCUMENTS.INDEX.NAME,
]

export const SETTING_ROUTES = [
  RouteList.SETTINGS.BUSINESS.BUSINESS.INDEX.NAME,
  RouteList.SETTINGS.BUSINESS.LOCATIONS.INDEX.NAME,
  RouteList.SETTINGS.BUSINESS.DATA_FIELDS.NAME,
  RouteList.SETTINGS.BUSINESS.INTEGRATIONS.INDEX.NAME,
  RouteList.SETTINGS.LMS.STATUSES.NAME,
  RouteList.SETTINGS.LMS.CHILD_STATUS.NAME,
  RouteList.SETTINGS.LMS.DATA_FIELDS.INDEX.NAME,
  RouteList.SETTINGS.EMS.STATUSES.NAME,
  RouteList.SETTINGS.EMS.DATA_FIELDS.INDEX.NAME,
  RouteList.SETTINGS.BRANDING.DOMAINS.INDEX.NAME,
  RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_THEMES.NAME,
  RouteList.SETTINGS.BRANDING.EMAIL.EMAIL_DESIGNS.NAME,
  RouteList.SETTINGS.BRANDING.EMAIL.CONFIG.NAME,
  RouteList.SETTINGS.BRANDING.PHONE_NUMBERS.INDEX.NAME,
  RouteList.SETTINGS.ACCESS.USERS.INDEX.NAME,
  RouteList.SETTINGS.ACCESS.USERS.INVITATIONS.NAME,
  RouteList.SETTINGS.ACCESS.ROLES.NAME,
]

export const TASK_ROUTES = [
  RouteList.TASKS.TASKS.ALL.NAME,
  RouteList.TASKS.TASKS.MY_TASKS.NAME,
  RouteList.TASKS.TASKS.ACTIONS_TO_TAKE.NAME,
  RouteList.TASKS.TASKS.WITHOUT_ASSIGNEE.NAME,
  RouteList.TASKS.TASKS.CREATED_BY_ME.NAME,
  RouteList.TASKS.TASKS.ARCHIVE.NAME,
  RouteList.TASKS.TASKS_TEMPLATES.INDEX.NAME,
  RouteList.TASKS.TASK_TYPES.INDEX.NAME,
]
