import { AxiosResponse } from 'axios'
import client from '@/api/client'
import { Response } from '@/api/PaginatedResponse'

export const LIST_SOCIAL_ACCOUNT_URL = '/user/social-accounts'
export const ATTACH_SOCIAL_ACCOUNT_URL = '/user/social-accounts'
export const DETACH_SOCIAL_ACCOUNT_URL = '/user/social-accounts/{id}'

export interface SocialCodeRequest {
  provider: string
  code: string
}

export interface SocialAccount {
  avatar: string
  email: string
  id: string
  provider: string
  provider_id: string
}

export const getSocialAccounts = async (): Promise<AxiosResponse<Response<SocialAccount>>> => {
  return await client.get(LIST_SOCIAL_ACCOUNT_URL)
}
export const attachSocialAccount = async (payload: SocialCodeRequest): Promise<AxiosResponse> => {
  return await client.post(ATTACH_SOCIAL_ACCOUNT_URL, payload)
}

export const detachSocialAccount = async (id: string) => {
  await client.delete(DETACH_SOCIAL_ACCOUNT_URL.replace('{id}', id))
}

export function getSocialRedirectUri(provider: string): string {
  let redirectUri: string = ''
  switch (provider) {
    case 'google':
      redirectUri =
        import.meta.env.VITE_GOOGLE_CLIENT_REDIRECT ?? 'http://localhost:3000/social/google'
      break
    case 'microsoft':
      redirectUri =
        import.meta.env.VITE_MICROSOFT_CLIENT_REDIRECT ?? 'http://localhost:3000/social/microsoft'
      break
    case 'facebook':
      redirectUri =
        import.meta.env.VITE_FACEBOOK_CLIENT_REDIRECT ?? 'http://localhost:3000/social/facebook'
      break
    default:
      break
  }

  return redirectUri
}
