<template>
  <div class="d-flex align-items-center text-muted">
    <div v-if="audit && audit.profile" class="d-flex align-items-center me-2">
      <span v-if="isByVisible" class="mx-1">{{ t('by') }}</span>
      <base-user-profile-badge :size="size" :profile="audit.profile" />
    </div>
    <div class="d-flex flex-wrap text-break ms-2">
      <span class="text-nowrap">{{ toLocalDateFormat(computedUpdatedAt) }},&nbsp;</span>
      <span class="text-nowrap">{{ toLocalTimeFormat(computedUpdatedAt) }} </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { Audit } from '@/dto/common/audit'
import { toLocalDateFormat, toLocalTimeFormat } from '@/helpers/date.helper'
import { BadgeSize } from '@/shared'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  audit: {
    type: Object as PropType<Audit | undefined | null>,
    default: undefined,
  },
  updatedAt: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<BadgeSize>,
    default: 'md',
    required: false,
  },
  isByVisible: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const { t } = useI18n()

const computedUpdatedAt = computed(() => (props.audit ? props.audit.created_at : props.updatedAt))
</script>
