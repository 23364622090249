import { zonedTimeToUtc } from 'date-fns-tz'
import store from '@/store'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

// TODO apply localization to dates, because US format does not apply to every business
// Also separate between long/short dates and time
const SHORT_DATE = 'M/D/YY' // in US format
// const LONG_DATE = 'M/D/YYYY' // in US format
const DATE_TIME = 'h:mm A' // in US format

export function getBusinessTimezone(): string {
  return store.getters['DashboardModule/timezone']
}

function isMatch(datetime: string | number | Date): boolean {
  return dayjs(datetime, 'YYYY-MM-DD', true).isValid()
}

export function toLocalDateTimeFormat(datetime: string | number | Date, timezone?: string): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
    : dayjs(datetime)
        .tz(timezone ?? getBusinessTimezone())
        .format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function toLocalDateFormat(datetime: string | number | Date): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${SHORT_DATE}`)
    : dayjs(datetime).tz(getBusinessTimezone()).format(`${SHORT_DATE}`)
}

export function toLocalLongDateFormat(datetime: string | number | Date): string {
  return isMatch(datetime)
    ? dayjs(datetime).format('MMMM D, YYYY')
    : dayjs(datetime).tz(getBusinessTimezone()).format('MMMM D, YYYY')
}

export function toLocalTimeFormat(datetime: string | number | Date, timezone?: string): string {
  return isMatch(datetime)
    ? dayjs(datetime).format(`${DATE_TIME}`)
    : dayjs(datetime)
        .tz(timezone ?? getBusinessTimezone())
        .format(`${DATE_TIME}`)
}

export function toLocalTime(time: string | undefined): string {
  if (time === undefined) {
    return ''
  }

  const [hours, minutes] = time.split(':')
  const date = new Date()
  date.setUTCHours(Number(hours))
  date.setUTCMinutes(Number(minutes))
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)

  return toLocalTimeFormat(date, 'UTC')
}
export function validDateRange(dateRange?: [Date, Date]): [Date, Date] {
  const today = new Date()

  return dateRange ?? [new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
}

export function dateRangeToOneYearPastFromToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
  return validDateRange(dateRange)
}

export function dateRangeToToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today)]
  return validDateRange(dateRange)
}

export function toCalendarDateTimeFormat(datetime: string | number | Date | undefined): string {
  if (datetime === undefined) {
    return ''
  }

  return isMatch(datetime)
    ? dayjs(datetime).format('YYYY-MM-DD HH:mm:ss')
    : dayjs(datetime).tz(getBusinessTimezone()).format('YYYY-MM-DD HH:mm:ss')
}

export function formatTimeToUTC(datetime: string | number | Date) {
  return zonedTimeToUtc(datetime, getBusinessTimezone()).toISOString()
}

export function formatDateToTimezone(datetime: string, timezone?: string) {
  return dayjs(datetime)
    .tz(timezone ?? getBusinessTimezone())
    .format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function formatDateToTimezoneCalendar(datetime: string, timezone: string) {
  return dayjs(datetime).tz(timezone).format('YYYY-MM-DD HH:mm:ss')
}

export function unixForPastDate(days = 0, startOfDay = true, timezone?: string) {
  return dayjs()
    .tz(timezone ?? getBusinessTimezone())
    .subtract(days, 'day')
    [startOfDay ? 'startOf' : 'endOf']('day')
    .unix()
}

export function toLocalDateTimeFormatFromUnix(datetime: number): string {
  return dayjs.unix(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
}

export function fromUnixToUTC(datetime: number): string {
  return dayjs.unix(datetime).toISOString().split('T')[0]
}

export function toDateTimeFormat(datetime: string | number | Date): string {
  return dayjs(datetime).format(`${SHORT_DATE}, ${DATE_TIME}`)
}
export function toDateFormat(datetime: string | number | Date): string {
  return dayjs(datetime).format(`${SHORT_DATE}`)
}
