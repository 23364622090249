import toDate from 'date-fns-tz/toDate'
import { BusinessUserProfileResponse } from '@/dto/Auth/ProfileDto'
import PermissionDto, {
  AbilityResponse,
  transformBusinessUserAbilityResponseToDto,
} from '@/dto/Auth/PermissionDto'
import RoleDto, {
  transformBusinessUserRoleResponseToDto,
} from '@/dto/Auth/RoleDto'

export interface UserQueryParams {
  search?: string
  page?: number
}

export type UserItemResponse = {
  id: string
  email: string | null
  phone: string | null
  created_at: string | null
  updated_at: string | null
  is_admin: boolean
  profile: BusinessUserProfileResponse | null
  abilities: AbilityResponse[]
  roles: RoleDto[]
  intercom_hash?: string
}

export default class UserDto {
  public id: string
  public email: string | null = null
  public phone: string | null = null
  public createdAt: Date | null = null
  public updatedAt: Date | null = null
  public isAdmin = false
  public profile: BusinessUserProfileResponse | null = null
  public abilities: PermissionDto[] = []
  public roles: RoleDto[] = []
  public intercomHash: string | undefined = undefined

  constructor(id: string) {
    this.id = id
  }
}

export function transformUserItemResponseToDto(
  userItem: UserItemResponse,
): UserDto {
  const userDto = new UserDto(userItem.id)
  userDto.email = userItem.email
  userDto.phone = userItem.phone
  userDto.createdAt = null !== userItem.created_at ? toDate(userItem.created_at) : null
  userDto.updatedAt = null !== userItem.updated_at ? toDate(userItem.updated_at) : null
  userDto.profile = userItem.profile
  userDto.isAdmin = userItem.is_admin
  userDto.abilities = userItem.abilities.map(transformBusinessUserAbilityResponseToDto)
  userDto.roles = userItem.roles.map(transformBusinessUserRoleResponseToDto)
  userDto.intercomHash = userItem.intercom_hash

  return userDto
}
