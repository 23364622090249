import type { CountryCode, E164Number, NationalNumber } from 'libphonenumber-js/types'
import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
  parsePhoneNumber,
  AsYouType,
  isPossiblePhoneNumber,
} from 'libphonenumber-js/max'

export function isValidPhoneNumber(phoneNumber: string, countryCode?: CountryCode): boolean {
  // Check if phoneNumber is possible first or parsePhoneNumber will throw errors
  if (!isPossiblePhoneNumber(phoneNumber, countryCode)) {
    return false
  }

  const phone = parsePhoneNumber(phoneNumber, countryCode)

  if (!phone) {
    return false
  }

  // Check if defaultCountry we provide is actually matching the country entered
  // We need this check, because if a correct international number is provided (for any country), the library will simply say that
  // the number isValid even if you provide Australian international number, but want it to be formatted as US number
  if (phone.country !== countryCode) {
    return false
  }

  return phone.isValid()
}

export function toNationalPretty(phoneNumber: string, countryCode?: CountryCode): NationalNumber {
  const country = countryCode ?? getPhoneNumberCountry(phoneNumber)
  return isValidPhoneNumber(phoneNumber, country)
    ? parsePhoneNumber(phoneNumber, country).formatNational()
    : asIsPretty(phoneNumber, country)
}

export function toInternationalPretty(phoneNumber: string): E164Number {
  return parsePhoneNumber(phoneNumber).formatInternational()
}

export function toInternational(phoneNumber: string, countryCode?: CountryCode): E164Number {
  return isValidPhoneNumber(phoneNumber, countryCode)
    ? parsePhoneNumber(phoneNumber, countryCode).number
    : asIs(phoneNumber)
}

export function asIs(phoneNumber: string): string {
  return parseIncompletePhoneNumber(phoneNumber)
}

export function asIsPretty(phoneNumber: string, countryCode?: CountryCode): string {
  return formatIncompletePhoneNumber(phoneNumber, countryCode)
}

export function getPhoneNumberCountry(phoneNumber: string) {
  // Can only parse phone number from international format
  const asYouType = new AsYouType()
  asYouType.input(phoneNumber)
  return asYouType.getCountry()
}

export function formattedNumber (number: string) {
  const phoneNumber = parsePhoneNumber(number)
  return phoneNumber.formatNational()
}
