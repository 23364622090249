<template>
  <router-view v-if="isLoaded" name="NavBar" />
  <main v-if="isLoaded" class="d-flex main">
    <router-view name="SideBar" />
    <router-view v-slot="{ Component }" class="spacing-for-chat-icons">
      <transition appear mode="out-in" name="fade">
        <!-- TODO revisit suspense after updating Vite, now it works only with 
        <Suspense timeout=0> but logs an error that it should wrap only a single node
        -->
        <component :is="Component" />
        <!-- <template #fallback>
            <loader class="position-static" />
          </template>
        </Suspense> -->
      </transition>
    </router-view>
    <div class="app-version text-muted px-2 py-1 fs-8">Version: {{ buildVersion }} </div>
    <reload-modal />
    <Intercom v-if="intercomEnabled && authStore.getters.isAuthenticated" />
    <OnRamp v-if="shownOnRampWidget" />
  </main>
  <BaseLoader v-else class="position-static" />
  <basic-overlay :show="isSubSidebarOpen" />
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useDashboard } from '@/store/Dashboard'
import { useBusiness } from '@/store/Business'
import { computed, onErrorCaptured, watch } from 'vue'
import { useAuth } from '@/store/Auth'
import { notifyError } from '@/helpers/notification.helper'
import { BuildVersion } from '@/shared'
import { useRoute } from 'vue-router'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { RouteList } from './routes/RouteList'
import { asyncComponentWrapper } from '@/composables/useReloadModal'

import ReloadModal from '@/components/Misc/ReloadModal.vue'
import OnRamp from '@/components/Misc/OnRampWrapper.vue'
const Intercom = asyncComponentWrapper(() => import('@/components/Misc/IntercomWrapper.vue'))
const BasicOverlay = asyncComponentWrapper(() => import('@/components/Misc/BasicOverlay.vue'))

const buildVersion = import.meta.env.VITE_BUILD_VERSION
const intercomEnabled = import.meta.env.VITE_INTERCOM_ENABLED

const authStore = useAuth()
const businessStore = useBusiness()
const dashboardStore = useDashboard()
const { t } = useI18n()
const route = useRoute()

const accessToken = computed(() => {
  return authStore.getters.accessToken
})

const isLoaded = computed(() => {
  return ![
    RouteList.AUTH.LOGIN.NAME,
    RouteList.AUTH.PASSWORD.FORGOT_PASSWORD.NAME,
    RouteList.AUTH.PASSWORD.RESET_PASSWORD.NAME,
    RouteList.AUTH.REGISTRATION.STEPS.USER.NAME,
  ].includes(route.name as string)
    ? authStore.getters.isStoreLoaded &&
        businessStore.getters.isStoreLoaded &&
        dashboardStore.getters.isStoreLoaded
    : true
})

const isSubSidebarOpen = computed(() => dashboardStore.getters.isSubSidebarOpen)
const shownOnRampWidget = computed(
  () =>
    authStore.getters.isAuthenticated &&
    import.meta.env.VITE_BUILD_VERSION?.match(BuildVersion.Production),
)

window.Pusher = Pusher

// Stops the website from breaking in 'Main' and 'Production' environments
if (import.meta.env.VITE_BUILD_VERSION?.match(BuildVersion.Main || BuildVersion.Production)) {
  onErrorCaptured((error) => {
    // Let's show the errors in console for 'Main' environment, while keeping the non-breaking
    // behavior the same as in 'Production'
    if (import.meta.env.VITE_BUILD_VERSION?.match(BuildVersion.Main)) {
      console.error(error)
    }

    notifyError(t('Something went wrong. Please reload the page'))
    return false
  })
}

watch(
  accessToken,
  (newToken) => {
    if (newToken) {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'us2',
        forceTLS: false,
        disableStats: true,
        channelAuthorization: {
          endpoint: `${import.meta.env.VITE_CORE_BASE_URL}/broadcasting/auth`,
          transport: 'ajax',
          headers: {
            Authorization: `Bearer ${authStore.getters.accessToken}`,
            Accept: 'application/json',
          },
        },
      })
    }
  },
  { immediate: true },
)
</script>
